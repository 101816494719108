*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.m-2 {
  margin: .5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-mr-12 {
  margin-right: -3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-56 {
  max-height: 14rem;
}

.min-h-0 {
  min-height: 0;
}

.min-h-full {
  min-height: 100%;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.appearance-none {
  appearance: none;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-3 {
  gap: .75rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-1\.5 {
  column-gap: .375rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-emerald-500\/20 {
  background-color: #10b98133;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(133 77 14 / var(--tw-bg-opacity));
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.placeholder\:text-gray-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.hover\:bg-blue-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.hover\:text-blue-100:hover {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-blue-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity));
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

.focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-blue-600:focus-visible {
  outline-color: #2563eb;
}

.group:hover .group-hover\:text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-10 {
    height: 2.5rem;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:leading-6 {
    line-height: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:fixed {
    position: fixed;
  }

  .lg\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg\:ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-64 {
    width: 16rem;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:pl-64 {
    padding-left: 16rem;
  }
}

.monaco-editor {
  --monaco-monospace-font: "SF Mono", Monaco, Menlo, Consolas, "Ubuntu Mono", "Liberation Mono", "DejaVu Sans Mono", "Courier New", monospace;
  font-family: -apple-system, BlinkMacSystemFont, Segoe WPC, Segoe UI, HelveticaNeue-Light, system-ui, Ubuntu, Droid Sans, sans-serif;
}

.monaco-menu .monaco-action-bar.vertical .action-item .action-menu-item:focus .action-label, .monaco-editor.vs-dark .monaco-menu .monaco-action-bar.vertical .action-menu-item:focus .action-label, .monaco-editor.hc-black .monaco-menu .monaco-action-bar.vertical .action-menu-item:focus .action-label, .monaco-editor.hc-light .monaco-menu .monaco-action-bar.vertical .action-menu-item:focus .action-label {
  stroke-width: 1.2px;
}

.monaco-hover p {
  margin: 0;
}

.monaco-aria-container {
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  margin: -1px;
  padding: 0;
  top: 0;
  overflow: hidden;
  position: absolute !important;
}

.monaco-aria-container {
  position: absolute;
  left: -999em;
}

::-ms-clear {
  display: none;
}

.monaco-editor .editor-widget input {
  color: inherit;
}

.monaco-editor {
  -webkit-text-size-adjust: 100%;
  color: var(--vscode-editor-foreground);
  background-color: var(--vscode-editor-background);
  position: relative;
  overflow: visible;
}

.monaco-editor-background {
  background-color: var(--vscode-editor-background);
}

.monaco-editor .rangeHighlight {
  background-color: var(--vscode-editor-rangeHighlightBackground);
  box-sizing: border-box;
  border: 1px solid var(--vscode-editor-rangeHighlightBorder);
}

.monaco-editor.hc-black .rangeHighlight, .monaco-editor.hc-light .rangeHighlight {
  border-style: dotted;
}

.monaco-editor .symbolHighlight {
  background-color: var(--vscode-editor-symbolHighlightBackground);
  box-sizing: border-box;
  border: 1px solid var(--vscode-editor-symbolHighlightBorder);
}

.monaco-editor.hc-black .symbolHighlight, .monaco-editor.hc-light .symbolHighlight {
  border-style: dotted;
}

.monaco-editor .overflow-guard {
  position: relative;
  overflow: hidden;
}

.monaco-editor .view-overlays {
  position: absolute;
  top: 0;
}

.monaco-editor .squiggly-error {
  border-bottom: 4px double var(--vscode-editorError-border);
}

.monaco-editor .squiggly-error:before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--vscode-editorError-background);
  display: block;
}

.monaco-editor .squiggly-warning {
  border-bottom: 4px double var(--vscode-editorWarning-border);
}

.monaco-editor .squiggly-warning:before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--vscode-editorWarning-background);
  display: block;
}

.monaco-editor .squiggly-info {
  border-bottom: 4px double var(--vscode-editorInfo-border);
}

.monaco-editor .squiggly-info:before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--vscode-editorInfo-background);
  display: block;
}

.monaco-editor .squiggly-hint {
  border-bottom: 2px dotted var(--vscode-editorHint-border);
}

.monaco-editor.showUnused .squiggly-unnecessary {
  border-bottom: 2px dashed var(--vscode-editorUnnecessaryCode-border);
}

.monaco-editor.showDeprecated .squiggly-inline-deprecated {
  text-decoration: line-through;
  -webkit-text-decoration-color: var(--vscode-editor-foreground, inherit);
  text-decoration-color: var(--vscode-editor-foreground, inherit);
}

.monaco-scrollable-element > .scrollbar > .scra {
  cursor: pointer;
  font-size: 11px !important;
}

.monaco-scrollable-element > .visible {
  opacity: 1;
  z-index: 11;
  background: none;
  transition: opacity .1s linear;
}

.monaco-scrollable-element > .invisible {
  opacity: 0;
  pointer-events: none;
}

.monaco-scrollable-element > .invisible.fade {
  transition: opacity .8s linear;
}

.monaco-scrollable-element > .shadow {
  display: none;
  position: absolute;
}

.monaco-scrollable-element > .shadow.top {
  height: 3px;
  width: 100%;
  box-shadow: var(--vscode-scrollbar-shadow) 0 6px 6px -6px inset;
  display: block;
  top: 0;
  left: 3px;
}

.monaco-scrollable-element > .shadow.left {
  height: 100%;
  width: 3px;
  box-shadow: var(--vscode-scrollbar-shadow) 6px 0 6px -6px inset;
  display: block;
  top: 3px;
  left: 0;
}

.monaco-scrollable-element > .shadow.top-left-corner {
  height: 3px;
  width: 3px;
  display: block;
  top: 0;
  left: 0;
}

.monaco-scrollable-element > .shadow.top.left {
  box-shadow: var(--vscode-scrollbar-shadow) 6px 0 6px -6px inset;
}

.monaco-scrollable-element > .scrollbar > .slider {
  background: var(--vscode-scrollbarSlider-background);
}

.monaco-scrollable-element > .scrollbar > .slider:hover {
  background: var(--vscode-scrollbarSlider-hoverBackground);
}

.monaco-scrollable-element > .scrollbar > .slider.active {
  background: var(--vscode-scrollbarSlider-activeBackground);
}

.monaco-editor .inputarea {
  min-width: 0;
  min-height: 0;
  resize: none;
  color: #0000;
  z-index: -10;
  background-color: #0000;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
  outline: none !important;
}

.monaco-editor .inputarea.ime-input {
  z-index: 10;
  caret-color: var(--vscode-editorCursor-foreground);
  color: var(--vscode-editor-foreground);
}

.monaco-editor .margin-view-overlays .line-numbers {
  font-variant-numeric: tabular-nums;
  text-align: right;
  vertical-align: middle;
  box-sizing: border-box;
  cursor: default;
  height: 100%;
  display: inline-block;
  position: absolute;
}

.monaco-editor .relative-current-line-number {
  text-align: left;
  width: 100%;
  display: inline-block;
}

.monaco-editor .margin-view-overlays .line-numbers.lh-odd {
  margin-top: 1px;
}

.monaco-editor .line-numbers {
  color: var(--vscode-editorLineNumber-foreground);
}

.monaco-editor .line-numbers.active-line-number {
  color: var(--vscode-editorLineNumber-activeForeground);
}

.monaco-editor .margin {
  background-color: var(--vscode-editorGutter-background);
}

.monaco-mouse-cursor-text {
  cursor: text;
}

.monaco-editor .view-overlays .current-line, .monaco-editor .margin-view-overlays .current-line {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-editor .margin-view-overlays .current-line.current-line-margin.current-line-margin-both {
  border-right: 0;
}

.monaco-editor .lines-content .cdr {
  position: absolute;
}

.monaco-editor .glyph-margin {
  position: absolute;
  top: 0;
}

.monaco-editor .margin-view-overlays .cgmr {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.monaco-editor .lines-content .core-guide {
  box-sizing: border-box;
  position: absolute;
}

.monaco-editor .lines-content .core-guide-indent {
  box-shadow: 1px 0 0 0 var(--vscode-editorIndentGuide-background) inset;
}

.monaco-editor .lines-content .core-guide-indent-active {
  box-shadow: 1px 0 0 0 var(--vscode-editorIndentGuide-activeBackground, --vscode-editorIndentGuide-background) inset;
}

.mtkcontrol {
  color: #fff !important;
  background: #960000 !important;
}

.mtkoverflow {
  background-color: var(--vscode-button-background, --vscode-editor-background);
  color: var(--vscode-button-foreground, --vscode-editor-foreground);
  border-style: solid;
  border-width: 1px;
  border-color: var(--vscode-contrastBorder);
  cursor: pointer;
  border-radius: 2px;
  padding: 4px;
}

.mtkoverflow:hover {
  background-color: var(--vscode-button-hoverBackground);
}

.monaco-editor.no-user-select .lines-content, .monaco-editor.no-user-select .view-line, .monaco-editor.no-user-select .view-lines {
  -webkit-user-select: none;
  user-select: none;
}

.monaco-editor.mac .lines-content:hover, .monaco-editor.mac .view-line:hover, .monaco-editor.mac .view-lines:hover {
  -webkit-user-select: text;
  user-select: text;
}

.monaco-editor.enable-user-select {
  user-select: initial;
  -webkit-user-select: initial;
}

.monaco-editor .view-lines {
  white-space: nowrap;
}

.monaco-editor .view-line {
  width: 100%;
  position: absolute;
}

.monaco-editor .mtkw {
  color: var(--vscode-editorWhitespace-foreground) !important;
}

.monaco-editor .mtkz {
  display: inline-block;
  color: var(--vscode-editorWhitespace-foreground) !important;
}

.monaco-editor .lines-decorations {
  background: #fff;
  position: absolute;
  top: 0;
}

.monaco-editor .margin-view-overlays .cldr {
  height: 100%;
  position: absolute;
}

.monaco-editor .margin-view-overlays .cmdr {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.monaco-editor .minimap.slider-mouseover .minimap-slider {
  opacity: 0;
  transition: opacity .1s linear;
}

.monaco-editor .minimap.slider-mouseover:hover .minimap-slider, .monaco-editor .minimap.slider-mouseover .minimap-slider.active {
  opacity: 1;
}

.monaco-editor .minimap-slider .minimap-slider-horizontal {
  background: var(--vscode-minimapSlider-background);
}

.monaco-editor .minimap-slider:hover .minimap-slider-horizontal {
  background: var(--vscode-minimapSlider-hoverBackground);
}

.monaco-editor .minimap-slider.active .minimap-slider-horizontal {
  background: var(--vscode-minimapSlider-activeBackground);
}

.monaco-editor .minimap-shadow-visible {
  box-shadow: var(--vscode-scrollbar-shadow) -6px 0 6px -6px inset;
}

.monaco-editor .minimap-shadow-hidden {
  width: 0;
  position: absolute;
}

.monaco-editor .minimap-shadow-visible {
  width: 6px;
  position: absolute;
  left: -6px;
}

.monaco-editor.no-minimap-shadow .minimap-shadow-visible {
  width: 1px;
  position: absolute;
  left: -1px;
}

.minimap.autohide {
  opacity: 0;
  transition: opacity .5s;
}

.minimap.autohide:hover {
  opacity: 1;
}

.monaco-editor .overlayWidgets {
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-editor .view-ruler {
  box-shadow: 1px 0 0 0 var(--vscode-editorRuler-foreground) inset;
  position: absolute;
  top: 0;
}

.monaco-editor .scroll-decoration {
  height: 6px;
  box-shadow: var(--vscode-scrollbar-shadow) 0 6px 6px -6px inset;
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-editor .lines-content .cslr {
  position: absolute;
}

.monaco-editor .focused .selected-text {
  background-color: var(--vscode-editor-selectionBackground);
}

.monaco-editor .selected-text {
  background-color: var(--vscode-editor-inactiveSelectionBackground);
}

.monaco-editor .top-left-radius {
  border-top-left-radius: 3px;
}

.monaco-editor .bottom-left-radius {
  border-bottom-left-radius: 3px;
}

.monaco-editor .top-right-radius {
  border-top-right-radius: 3px;
}

.monaco-editor .bottom-right-radius {
  border-bottom-right-radius: 3px;
}

.monaco-editor.hc-black .top-left-radius {
  border-top-left-radius: 0;
}

.monaco-editor.hc-black .bottom-left-radius {
  border-bottom-left-radius: 0;
}

.monaco-editor.hc-black .top-right-radius {
  border-top-right-radius: 0;
}

.monaco-editor.hc-black .bottom-right-radius {
  border-bottom-right-radius: 0;
}

.monaco-editor.hc-light .top-left-radius {
  border-top-left-radius: 0;
}

.monaco-editor.hc-light .bottom-left-radius {
  border-bottom-left-radius: 0;
}

.monaco-editor.hc-light .top-right-radius {
  border-top-right-radius: 0;
}

.monaco-editor.hc-light .bottom-right-radius {
  border-bottom-right-radius: 0;
}

.monaco-editor .cursors-layer {
  position: absolute;
  top: 0;
}

.monaco-editor .cursors-layer > .cursor {
  box-sizing: border-box;
  position: absolute;
  overflow: hidden;
}

.monaco-editor .cursors-layer.cursor-smooth-caret-animation > .cursor {
  transition: all 80ms;
}

.monaco-editor .cursors-layer.cursor-block-outline-style > .cursor {
  border-style: solid;
  border-width: 1px;
  background: none !important;
}

.monaco-editor .cursors-layer.cursor-underline-style > .cursor {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  background: none !important;
}

.monaco-editor .cursors-layer.cursor-underline-thin-style > .cursor {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background: none !important;
}

@keyframes monaco-cursor-smooth {
  0%, 20% {
    opacity: 1;
  }

  60%, 100% {
    opacity: 0;
  }
}

@keyframes monaco-cursor-phase {
  0%, 20% {
    opacity: 1;
  }

  90%, 100% {
    opacity: 0;
  }
}

@keyframes monaco-cursor-expand {
  0%, 20% {
    transform: scaleY(1);
  }

  80%, 100% {
    transform: scaleY(0);
  }
}

.cursor-smooth {
  animation: .5s ease-in-out 20 alternate monaco-cursor-smooth;
}

.cursor-phase {
  animation: .5s ease-in-out 20 alternate monaco-cursor-phase;
}

.cursor-expand > .cursor {
  animation: .5s ease-in-out 20 alternate monaco-cursor-expand;
}

.monaco-editor .blockDecorations-container {
  pointer-events: none;
  position: absolute;
  top: 0;
}

.monaco-editor .blockDecorations-block {
  box-sizing: border-box;
  position: absolute;
}

.monaco-editor .mwh {
  position: absolute;
  color: var(--vscode-editorWhitespace-foreground) !important;
}

:root {
  --vscode-sash-size: 4px;
}

.monaco-sash {
  z-index: 35;
  touch-action: none;
  position: absolute;
}

.monaco-sash.disabled {
  pointer-events: none;
}

.monaco-sash.mac.vertical {
  cursor: col-resize;
}

.monaco-sash.vertical.minimum {
  cursor: e-resize;
}

.monaco-sash.vertical.maximum {
  cursor: w-resize;
}

.monaco-sash.mac.horizontal {
  cursor: row-resize;
}

.monaco-sash.horizontal.minimum {
  cursor: s-resize;
}

.monaco-sash.horizontal.maximum {
  cursor: n-resize;
}

.monaco-sash.disabled {
  cursor: default !important;
  pointer-events: none !important;
}

.monaco-sash.vertical {
  cursor: ew-resize;
  width: var(--vscode-sash-size);
  height: 100%;
  top: 0;
}

.monaco-sash.horizontal {
  cursor: ns-resize;
  width: 100%;
  height: var(--vscode-sash-size);
  left: 0;
}

.monaco-sash:not(.disabled) > .orthogonal-drag-handle {
  content: " ";
  height: calc(var(--vscode-sash-size) * 2);
  width: calc(var(--vscode-sash-size) * 2);
  z-index: 100;
  cursor: all-scroll;
  display: block;
  position: absolute;
}

.monaco-sash.horizontal.orthogonal-edge-north:not(.disabled) > .orthogonal-drag-handle.start, .monaco-sash.horizontal.orthogonal-edge-south:not(.disabled) > .orthogonal-drag-handle.end {
  cursor: nwse-resize;
}

.monaco-sash.horizontal.orthogonal-edge-north:not(.disabled) > .orthogonal-drag-handle.end, .monaco-sash.horizontal.orthogonal-edge-south:not(.disabled) > .orthogonal-drag-handle.start {
  cursor: nesw-resize;
}

.monaco-sash.vertical > .orthogonal-drag-handle.start {
  left: calc(var(--vscode-sash-size) * -.5);
  top: calc(var(--vscode-sash-size) * -1);
}

.monaco-sash.vertical > .orthogonal-drag-handle.end {
  left: calc(var(--vscode-sash-size) * -.5);
  bottom: calc(var(--vscode-sash-size) * -1);
}

.monaco-sash.horizontal > .orthogonal-drag-handle.start {
  top: calc(var(--vscode-sash-size) * -.5);
  left: calc(var(--vscode-sash-size) * -1);
}

.monaco-sash.horizontal > .orthogonal-drag-handle.end {
  top: calc(var(--vscode-sash-size) * -.5);
  right: calc(var(--vscode-sash-size) * -1);
}

.monaco-sash:before {
  content: "";
  pointer-events: none;
  width: 100%;
  height: 100%;
  background: none;
  position: absolute;
}

.monaco-workbench:not(.reduce-motion) .monaco-sash:before {
  transition: background-color .1s ease-out;
}

.monaco-sash.hover:before, .monaco-sash.active:before {
  background: var(--vscode-sash-hoverBorder);
}

.monaco-sash.vertical:before {
  width: var(--vscode-sash-hover-size);
  left: calc(50% - (var(--vscode-sash-hover-size) / 2));
}

.monaco-sash.horizontal:before {
  height: var(--vscode-sash-hover-size);
  top: calc(50% - (var(--vscode-sash-hover-size) / 2));
}

.pointer-events-disabled {
  pointer-events: none !important;
}

.monaco-sash.debug {
  background: #0ff;
}

.monaco-sash.debug.disabled {
  background: #0ff3;
}

.monaco-sash.debug:not(.disabled) > .orthogonal-drag-handle {
  background: red;
}

.monaco-diff-editor .diffOverview {
  z-index: 9;
}

.monaco-diff-editor .diffOverview .diffViewport {
  z-index: 10;
}

.monaco-diff-editor.vs .diffOverview {
  background: #00000008;
}

.monaco-diff-editor.vs-dark .diffOverview {
  background: #ffffff03;
}

.monaco-scrollable-element.modified-in-monaco-diff-editor.vs .scrollbar, .monaco-scrollable-element.modified-in-monaco-diff-editor.vs-dark .scrollbar, .monaco-scrollable-element.modified-in-monaco-diff-editor.hc-black .scrollbar, .monaco-scrollable-element.modified-in-monaco-diff-editor.hc-light .scrollbar {
  background: none;
}

.monaco-scrollable-element.modified-in-monaco-diff-editor .slider {
  z-index: 10;
}

.modified-in-monaco-diff-editor .slider.active {
  background: #ababab66;
}

.modified-in-monaco-diff-editor.hc-black .slider.active, .modified-in-monaco-diff-editor.hc-light .slider.active {
  background: none;
}

.monaco-editor .insert-sign, .monaco-diff-editor .insert-sign, .monaco-editor .delete-sign, .monaco-diff-editor .delete-sign {
  align-items: center;
  opacity: .7 !important;
  font-size: 11px !important;
  display: flex !important;
}

.monaco-editor.hc-black .insert-sign, .monaco-diff-editor.hc-black .insert-sign, .monaco-editor.hc-black .delete-sign, .monaco-diff-editor.hc-black .delete-sign, .monaco-editor.hc-light .insert-sign, .monaco-diff-editor.hc-light .insert-sign, .monaco-editor.hc-light .delete-sign, .monaco-diff-editor.hc-light .delete-sign {
  opacity: 1;
}

.monaco-editor .inline-deleted-margin-view-zone, .monaco-editor .inline-added-margin-view-zone {
  text-align: right;
}

.monaco-editor .arrow-revert-change {
  z-index: 10;
  position: absolute;
}

.monaco-editor .arrow-revert-change:hover {
  cursor: pointer;
}

.monaco-editor .view-zones .view-lines .view-line span {
  display: inline-block;
}

.monaco-editor .margin-view-zones .lightbulb-glyph:hover {
  cursor: pointer;
}

.monaco-editor .char-insert, .monaco-diff-editor .char-insert {
  background-color: var(--vscode-diffEditor-insertedTextBackground);
}

.monaco-editor .line-insert, .monaco-diff-editor .line-insert {
  background-color: var(--vscode-diffEditor-insertedLineBackground, --vscode-diffEditor-insertedTextBackground);
}

.monaco-editor .line-insert, .monaco-editor .char-insert {
  box-sizing: border-box;
  border: 1px solid var(--vscode-diffEditor-insertedTextBorder);
}

.monaco-editor.hc-black .line-insert, .monaco-editor.hc-light .line-insert, .monaco-editor.hc-black .char-insert, .monaco-editor.hc-light .char-insert {
  border-style: dashed;
}

.monaco-editor .line-delete, .monaco-editor .char-delete {
  box-sizing: border-box;
  border: 1px solid var(--vscode-diffEditor-removedTextBorder);
}

.monaco-editor.hc-black .line-delete, .monaco-editor.hc-light .line-delete, .monaco-editor.hc-black .char-delete, .monaco-editor.hc-light .char-delete {
  border-style: dashed;
}

.monaco-editor .inline-added-margin-view-zone, .monaco-editor .gutter-insert, .monaco-diff-editor .gutter-insert {
  background-color: var(--vscode-diffEditorGutter-insertedLineBackground, --vscode-diffEditor-insertedLineBackground, --vscode-diffEditor-insertedTextBackground);
}

.monaco-editor .char-delete, .monaco-diff-editor .char-delete {
  background-color: var(--vscode-diffEditor-removedTextBackground);
}

.monaco-editor .line-delete, .monaco-diff-editor .line-delete {
  background-color: var(--vscode-diffEditor-removedLineBackground, --vscode-diffEditor-removedTextBackground);
}

.monaco-editor .inline-deleted-margin-view-zone, .monaco-editor .gutter-delete, .monaco-diff-editor .gutter-delete {
  background-color: var(--vscode-diffEditorGutter-removedLineBackground, --vscode-diffEditor-removedLineBackground, --vscode-diffEditor-removedTextBackground);
}

.monaco-diff-editor.side-by-side .editor.modified {
  box-shadow: -6px 0 5px -5px var(--vscode-scrollbar-shadow);
  border-left: 1px solid var(--vscode-diffEditor-border);
}

.monaco-diff-editor .diffViewport {
  background: var(--vscode-scrollbarSlider-background);
}

.monaco-diff-editor .diffViewport:hover {
  background: var(--vscode-scrollbarSlider-hoverBackground);
}

.monaco-diff-editor .diffViewport:active {
  background: var(--vscode-scrollbarSlider-activeBackground);
}

.monaco-diff-editor .diff-review-line-number {
  text-align: right;
  color: var(--vscode-editorLineNumber-foreground);
  display: inline-block;
}

.monaco-diff-editor .diff-review {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
}

.monaco-diff-editor .diff-review-summary {
  padding-left: 10px;
}

.monaco-diff-editor .diff-review-shadow {
  box-shadow: var(--vscode-scrollbar-shadow) 0 -6px 6px -6px inset;
  position: absolute;
}

.monaco-diff-editor .diff-review-row {
  white-space: pre;
}

.monaco-diff-editor .diff-review-table {
  min-width: 100%;
  display: table;
}

.monaco-diff-editor .diff-review-row {
  width: 100%;
  display: table-row;
}

.monaco-diff-editor .diff-review-spacer {
  width: 10px;
  vertical-align: middle;
  display: inline-block;
}

.monaco-diff-editor .diff-review-spacer > .codicon {
  font-size: 9px !important;
}

.monaco-diff-editor .diff-review-actions {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 10px;
}

.monaco-diff-editor .diff-review-actions .action-label {
  width: 16px;
  height: 16px;
  margin: 2px 0;
}

.monaco-list {
  height: 100%;
  width: 100%;
  white-space: nowrap;
  position: relative;
}

.monaco-list.mouse-support {
  -webkit-user-select: none;
  user-select: none;
}

.monaco-list > .monaco-scrollable-element {
  height: 100%;
}

.monaco-list-rows {
  width: 100%;
  height: 100%;
  position: relative;
}

.monaco-list.horizontal-scrolling .monaco-list-rows {
  width: auto;
  min-width: 100%;
}

.monaco-list-row {
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.monaco-list.mouse-support .monaco-list-row {
  cursor: pointer;
  touch-action: none;
}

.monaco-list-row.scrolling {
  display: none !important;
}

.monaco-list.element-focused, .monaco-list.selection-single, .monaco-list.selection-multiple {
  outline: 0 !important;
}

.monaco-drag-image {
  z-index: 1000;
  border-radius: 10px;
  padding: 1px 7px;
  font-size: 12px;
  display: inline-block;
  position: absolute;
}

.monaco-list-type-filter-message {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: normal;
  opacity: .7;
  pointer-events: none;
  padding: 40px 1em 1em;
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-list-type-filter-message:empty {
  display: none;
}

.monaco-select-box-dropdown-padding {
  --dropdown-padding-top: 1px;
  --dropdown-padding-bottom: 1px;
}

.hc-black .monaco-select-box-dropdown-padding, .hc-light .monaco-select-box-dropdown-padding {
  --dropdown-padding-top: 3px;
  --dropdown-padding-bottom: 4px;
}

.monaco-select-box-dropdown-container {
  box-sizing: border-box;
  display: none;
}

.monaco-select-box-dropdown-container > .select-box-details-pane > .select-box-description-markdown * {
  margin: 0;
}

.monaco-select-box-dropdown-container > .select-box-details-pane > .select-box-description-markdown a:focus {
  outline: 1px solid -webkit-focus-ring-color;
  outline-offset: -1px;
}

.monaco-select-box-dropdown-container > .select-box-details-pane > .select-box-description-markdown code {
  line-height: 15px;
  font-family: var(--monaco-monospace-font);
}

.monaco-select-box-dropdown-container.visible {
  text-align: left;
  width: 1px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.monaco-select-box-dropdown-container > .select-box-dropdown-list-container {
  padding-top: var(--dropdown-padding-top);
  padding-bottom: var(--dropdown-padding-bottom);
  width: 100%;
  box-sizing: border-box;
  flex: none;
  align-self: flex-start;
  padding-left: 1px;
  padding-right: 1px;
  overflow: hidden;
}

.monaco-select-box-dropdown-container > .select-box-details-pane {
  padding: 5px;
}

.hc-black .monaco-select-box-dropdown-container > .select-box-dropdown-list-container {
  padding-top: var(--dropdown-padding-top);
  padding-bottom: var(--dropdown-padding-bottom);
}

.monaco-select-box-dropdown-container > .select-box-dropdown-list-container .monaco-list .monaco-list-row {
  cursor: pointer;
}

.monaco-select-box-dropdown-container > .select-box-dropdown-list-container .monaco-list .monaco-list-row > .option-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
  padding-left: 3.5px;
  overflow: hidden;
}

.monaco-select-box-dropdown-container > .select-box-dropdown-list-container .monaco-list .monaco-list-row > .option-detail {
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
  opacity: .7;
  padding-left: 3.5px;
  overflow: hidden;
}

.monaco-select-box-dropdown-container > .select-box-dropdown-list-container .monaco-list .monaco-list-row > .option-decorator-right {
  text-overflow: ellipsis;
  white-space: nowrap;
  float: right;
  padding-right: 10px;
  overflow: hidden;
}

.monaco-select-box-dropdown-container > .select-box-dropdown-list-container .monaco-list .monaco-list-row > .visually-hidden {
  width: 1px;
  height: 1px;
  position: absolute;
  top: auto;
  left: -10000px;
  overflow: hidden;
}

.monaco-select-box-dropdown-container > .select-box-dropdown-container-width-control {
  opacity: 0;
  flex: auto;
  align-self: flex-start;
}

.monaco-select-box-dropdown-container > .select-box-dropdown-container-width-control > .width-control-div {
  max-height: 0;
  overflow: hidden;
}

.monaco-select-box-dropdown-container > .select-box-dropdown-container-width-control > .width-control-div > .option-text-width-control {
  white-space: nowrap;
  padding-left: 4px;
  padding-right: 8px;
}

.monaco-select-box {
  width: 100%;
  cursor: pointer;
  border-radius: 2px;
}

.monaco-select-box-dropdown-container {
  text-transform: none;
  font-size: 13px;
  font-weight: normal;
}

.monaco-action-bar .action-item.select-container {
  cursor: default;
}

.monaco-action-bar .action-item .monaco-select-box {
  cursor: pointer;
  min-width: 100px;
  min-height: 18px;
  padding: 2px 23px 2px 8px;
}

.mac .monaco-action-bar .action-item .monaco-select-box {
  border-radius: 5px;
  font-size: 11px;
}

.monaco-action-bar {
  white-space: nowrap;
  height: 100%;
}

.monaco-action-bar .actions-container {
  height: 100%;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

.monaco-action-bar.vertical .actions-container {
  display: inline-block;
}

.monaco-action-bar .action-item {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: block;
  position: relative;
}

.monaco-action-bar .action-item.disabled {
  cursor: default;
}

.monaco-action-bar .action-item .icon, .monaco-action-bar .action-item .codicon {
  display: block;
}

.monaco-action-bar .action-item .codicon {
  width: 16px;
  height: 16px;
  align-items: center;
  display: flex;
}

.monaco-action-bar .action-label {
  border-radius: 5px;
  padding: 3px;
  font-size: 11px;
  display: flex;
}

.monaco-action-bar .action-item.disabled .action-label, .monaco-action-bar .action-item.disabled .action-label:before, .monaco-action-bar .action-item.disabled .action-label:hover {
  opacity: .6;
}

.monaco-action-bar.vertical {
  text-align: left;
}

.monaco-action-bar.vertical .action-item {
  display: block;
}

.monaco-action-bar.vertical .action-label.separator {
  border-bottom: 1px solid #bbb;
  margin-left: .8em;
  margin-right: .8em;
  padding-top: 1px;
  display: block;
}

.monaco-action-bar .action-item .action-label.separator {
  width: 1px;
  height: 16px;
  cursor: default;
  min-width: 1px;
  background-color: #bbb;
  padding: 0;
  margin: 5px 4px !important;
}

.secondary-actions .monaco-action-bar .action-label {
  margin-left: 6px;
}

.monaco-action-bar .action-item.select-container {
  max-width: 170px;
  min-width: 60px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
  overflow: hidden;
}

.monaco-action-bar .action-item.action-dropdown-item {
  display: flex;
}

.monaco-action-bar .action-item.action-dropdown-item > .action-dropdown-item-separator {
  cursor: default;
  align-items: center;
  display: flex;
}

.monaco-action-bar .action-item.action-dropdown-item > .action-dropdown-item-separator > div {
  width: 1px;
}

.context-view {
  position: absolute;
}

.context-view.fixed {
  all: initial;
  color: inherit;
  font-family: inherit;
  font-size: 13px;
  position: fixed;
}

.monaco-dropdown {
  height: 100%;
  padding: 0;
}

.monaco-dropdown > .dropdown-label {
  cursor: pointer;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.monaco-dropdown > .dropdown-label > .action-label.disabled {
  cursor: default;
}

.monaco-dropdown-with-primary {
  border-radius: 5px;
  flex-direction: row;
  display: flex !important;
}

.monaco-dropdown-with-primary > .action-container > .action-label {
  margin-right: 0;
}

.monaco-dropdown-with-primary > .dropdown-action-container > .monaco-dropdown > .dropdown-label .codicon[class*="codicon-"] {
  margin-left: -3px;
  padding-left: 0;
  padding-right: 0;
  font-size: 12px;
  line-height: 16px;
}

.monaco-dropdown-with-primary > .dropdown-action-container > .monaco-dropdown > .dropdown-label > .action-label {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  display: block;
}

.monaco-action-bar .action-item.menu-entry .action-label.icon {
  width: 16px;
  height: 16px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 16px;
}

.monaco-dropdown-with-default {
  border-radius: 5px;
  flex-direction: row;
  display: flex !important;
}

.monaco-dropdown-with-default > .action-container > .action-label {
  margin-right: 0;
}

.monaco-dropdown-with-default > .action-container.menu-entry > .action-label.icon {
  width: 16px;
  height: 16px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 16px;
}

.monaco-dropdown-with-default > .dropdown-action-container > .monaco-dropdown > .dropdown-label .codicon[class*="codicon-"] {
  margin-left: -3px;
  padding-left: 0;
  padding-right: 0;
  font-size: 12px;
  line-height: 16px;
}

.monaco-dropdown-with-default > .dropdown-action-container > .monaco-dropdown > .dropdown-label > .action-label {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  display: block;
}

.quick-input-widget {
  font-size: 13px;
}

.quick-input-widget .monaco-highlighted-label .highlight, .quick-input-widget .monaco-highlighted-label .highlight {
  color: #0066bf;
}

.vs .quick-input-widget .monaco-list-row.focused .monaco-highlighted-label .highlight, .vs .quick-input-widget .monaco-list-row.focused .monaco-highlighted-label .highlight {
  color: #9dddff;
}

.vs-dark .quick-input-widget .monaco-highlighted-label .highlight, .vs-dark .quick-input-widget .monaco-highlighted-label .highlight {
  color: #0097fb;
}

.hc-black .quick-input-widget .monaco-highlighted-label .highlight, .hc-black .quick-input-widget .monaco-highlighted-label .highlight {
  color: #f38518;
}

.hc-light .quick-input-widget .monaco-highlighted-label .highlight, .hc-light .quick-input-widget .monaco-highlighted-label .highlight {
  color: #0f4a85;
}

.monaco-keybinding > .monaco-keybinding-key {
  color: #555;
  background-color: #ddd6;
  border: 1px solid #ccc6;
  border-bottom-color: #bbb6;
  box-shadow: inset 0 -1px #bbb6;
}

.hc-black .monaco-keybinding > .monaco-keybinding-key {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border: 1px solid #6fc3df;
}

.hc-light .monaco-keybinding > .monaco-keybinding-key {
  box-shadow: none;
  color: #292929;
  background-color: #0000;
  border: 1px solid #0f4a85;
}

.vs-dark .monaco-keybinding > .monaco-keybinding-key {
  color: #ccc;
  background-color: #8080802b;
  border: 1px solid #3339;
  border-bottom-color: #4449;
  box-shadow: inset 0 -1px #4449;
}

.monaco-split-view2 {
  width: 100%;
  height: 100%;
  position: relative;
}

.monaco-split-view2 > .sash-container {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
}

.monaco-split-view2 > .sash-container > .monaco-sash {
  pointer-events: initial;
}

.monaco-split-view2 > .monaco-scrollable-element {
  width: 100%;
  height: 100%;
}

.monaco-split-view2 > .monaco-scrollable-element > .split-view-container {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  position: relative;
}

.monaco-split-view2 > .monaco-scrollable-element > .split-view-container > .split-view-view {
  white-space: initial;
  position: absolute;
}

.monaco-split-view2 > .monaco-scrollable-element > .split-view-container > .split-view-view:not(.visible) {
  display: none;
}

.monaco-split-view2.vertical > .monaco-scrollable-element > .split-view-container > .split-view-view {
  width: 100%;
}

.monaco-split-view2.horizontal > .monaco-scrollable-element > .split-view-container > .split-view-view {
  height: 100%;
}

.monaco-split-view2.separator-border > .monaco-scrollable-element > .split-view-container > .split-view-view:not(:first-child):before {
  content: " ";
  z-index: 5;
  pointer-events: none;
  background-color: var(--separator-border);
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-split-view2.separator-border.horizontal > .monaco-scrollable-element > .split-view-container > .split-view-view:not(:first-child):before {
  height: 100%;
  width: 1px;
}

.monaco-split-view2.separator-border.vertical > .monaco-scrollable-element > .split-view-container > .split-view-view:not(:first-child):before {
  height: 1px;
  width: 100%;
}

.monaco-table {
  height: 100%;
  width: 100%;
  white-space: nowrap;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.monaco-table > .monaco-split-view2 {
  border-bottom: 1px solid #0000;
}

.monaco-table > .monaco-list {
  flex: 1;
}

.monaco-table-tr {
  height: 100%;
  display: flex;
}

.monaco-table-th {
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  font-weight: bold;
  overflow: hidden;
}

.monaco-table-th, .monaco-table-td {
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
  overflow: hidden;
}

.monaco-table > .monaco-split-view2 .monaco-sash.vertical:before {
  content: "";
  left: calc(var(--vscode-sash-size) / 2);
  width: 0;
  border-left: 1px solid #0000;
  position: absolute;
}

.monaco-workbench:not(.reduce-motion) .monaco-table > .monaco-split-view2, .monaco-workbench:not(.reduce-motion) .monaco-table > .monaco-split-view2 .monaco-sash.vertical:before {
  transition: border-color .2s ease-out;
}

.monaco-custom-toggle {
  float: left;
  cursor: pointer;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #0000;
  border-radius: 3px;
  margin-left: 2px;
  padding: 1px;
  overflow: hidden;
}

.monaco-custom-toggle:hover {
  background-color: var(--vscode-inputOption-hoverBackground);
}

.hc-black .monaco-custom-toggle:hover, .hc-light .monaco-custom-toggle:hover {
  border: 1px dashed var(--vscode-focusBorder);
}

.hc-black .monaco-custom-toggle, .hc-light .monaco-custom-toggle, .hc-black .monaco-custom-toggle:hover, .hc-light .monaco-custom-toggle:hover {
  background: none;
}

.monaco-custom-toggle.monaco-checkbox {
  height: 18px;
  width: 18px;
  opacity: 1;
  border: 1px solid #0000;
  border-radius: 3px;
  margin-left: 0;
  margin-right: 9px;
  padding: 0;
  background-size: 16px !important;
}

.monaco-custom-toggle.monaco-checkbox:not(.checked):before {
  visibility: hidden;
}

.monaco-inputbox {
  box-sizing: border-box;
  font-size: inherit;
  border-radius: 2px;
  padding: 0;
  display: block;
  position: relative;
}

.monaco-inputbox > .ibwrapper > .input, .monaco-inputbox > .ibwrapper > .mirror {
  padding: 4px 6px;
}

.monaco-inputbox > .ibwrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.monaco-inputbox > .ibwrapper > .input {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
  resize: none;
  color: inherit;
  border: none;
  display: inline-block;
}

.monaco-inputbox > .ibwrapper > input {
  text-overflow: ellipsis;
}

.monaco-inputbox > .ibwrapper > textarea.input {
  scrollbar-width: none;
  outline: none;
  display: block;
}

.monaco-inputbox > .ibwrapper > textarea.input::-webkit-scrollbar {
  display: none;
}

.monaco-inputbox > .ibwrapper > textarea.input.empty {
  white-space: nowrap;
}

.monaco-inputbox > .ibwrapper > .mirror {
  width: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
  visibility: hidden;
  word-wrap: break-word;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-inputbox-container {
  text-align: right;
}

.monaco-inputbox-container .monaco-inputbox-message {
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-top: -1px;
  padding: .4em;
  font-size: 12px;
  line-height: 17px;
  display: inline-block;
  overflow: hidden;
}

.monaco-inputbox .monaco-action-bar {
  position: absolute;
  top: 4px;
  right: 2px;
}

.monaco-inputbox .monaco-action-bar .action-item {
  margin-left: 2px;
}

.monaco-inputbox .monaco-action-bar .action-item .codicon {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
}

.monaco-findInput {
  position: relative;
}

.monaco-findInput .monaco-inputbox {
  width: 100%;
  font-size: 13px;
}

.monaco-findInput > .controls {
  position: absolute;
  top: 3px;
  right: 2px;
}

.vs .monaco-findInput.disabled {
  background-color: #e1e1e1;
}

.vs-dark .monaco-findInput.disabled {
  background-color: #333;
}

.monaco-findInput.highlight-0 .controls, .hc-light .monaco-findInput.highlight-0 .controls {
  animation: .1s linear monaco-findInput-highlight-0;
}

.monaco-findInput.highlight-1 .controls, .hc-light .monaco-findInput.highlight-1 .controls {
  animation: .1s linear monaco-findInput-highlight-1;
}

.hc-black .monaco-findInput.highlight-0 .controls, .vs-dark .monaco-findInput.highlight-0 .controls {
  animation: .1s linear monaco-findInput-highlight-dark-0;
}

.hc-black .monaco-findInput.highlight-1 .controls, .vs-dark .monaco-findInput.highlight-1 .controls {
  animation: .1s linear monaco-findInput-highlight-dark-1;
}

@keyframes monaco-findInput-highlight-0 {
  0% {
    background: #fdff00cc;
  }

  100% {
    background: none;
  }
}

@keyframes monaco-findInput-highlight-1 {
  0% {
    background: #fdff00cc;
  }

  99% {
    background: none;
  }
}

@keyframes monaco-findInput-highlight-dark-0 {
  0% {
    background: #ffffff70;
  }

  100% {
    background: none;
  }
}

@keyframes monaco-findInput-highlight-dark-1 {
  0% {
    background: #ffffff70;
  }

  99% {
    background: none;
  }
}

.monaco-tl-row {
  height: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.monaco-tl-row.disabled {
  cursor: default;
}

.monaco-tl-indent {
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 16px;
}

.hide-arrows .monaco-tl-indent {
  left: 12px;
}

.monaco-tl-indent > .indent-guide {
  box-sizing: border-box;
  height: 100%;
  border-left: 1px solid #0000;
  display: inline-block;
}

.monaco-workbench:not(.reduce-motion) .monaco-tl-indent > .indent-guide {
  transition: border-color .1s linear;
}

.monaco-tl-twistie, .monaco-tl-contents {
  height: 100%;
}

.monaco-tl-twistie {
  text-align: right;
  width: 16px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding-right: 6px;
  font-size: 10px;
  transform: translateX(3px);
  display: flex !important;
}

.monaco-tl-contents {
  flex: 1;
  overflow: hidden;
}

.monaco-tl-twistie:before {
  border-radius: 20px;
}

.monaco-tl-twistie.collapsed:before {
  transform: rotate(-90deg);
}

.monaco-tl-twistie.codicon-tree-item-loading:before {
  animation: 1.25s steps(30, end) infinite codicon-spin;
}

.monaco-tree-type-filter {
  max-width: 200px;
  z-index: 100;
  border: 1px solid var(--vscode-widget-border);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin: 0 6px;
  padding: 3px;
  display: flex;
  position: absolute;
  top: 0;
}

.monaco-workbench:not(.reduce-motion) .monaco-tree-type-filter {
  transition: top .3s;
}

.monaco-tree-type-filter.disabled {
  top: -40px !important;
}

.monaco-tree-type-filter-grab {
  cursor: grab;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  display: flex !important;
}

.monaco-tree-type-filter-grab.grabbing {
  cursor: grabbing;
}

.monaco-tree-type-filter-input {
  flex: 1;
}

.monaco-tree-type-filter-input .monaco-inputbox {
  height: 23px;
}

.monaco-tree-type-filter-input .monaco-inputbox > .ibwrapper > .input, .monaco-tree-type-filter-input .monaco-inputbox > .ibwrapper > .mirror {
  padding: 2px 4px;
}

.monaco-tree-type-filter-input .monaco-findInput > .controls {
  top: 2px;
}

.monaco-tree-type-filter-actionbar {
  margin-left: 4px;
}

.monaco-tree-type-filter-actionbar .monaco-action-bar .action-label {
  padding: 2px;
}

.monaco-text-button {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--vscode-button-border, transparent);
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  line-height: 18px;
  display: flex;
}

.monaco-text-button:focus {
  outline-offset: 2px !important;
}

.monaco-text-button:hover {
  text-decoration: none !important;
}

.monaco-button.disabled:focus, .monaco-button.disabled {
  cursor: default;
  opacity: .4 !important;
}

.monaco-text-button .codicon {
  margin: 0 .2em;
  color: inherit !important;
}

.monaco-text-button.monaco-text-button-with-short-label {
  height: 28px;
  flex-flow: wrap;
  padding: 0 4px;
  overflow: hidden;
}

.monaco-text-button.monaco-text-button-with-short-label > .monaco-button-label {
  flex-basis: 100%;
}

.monaco-text-button.monaco-text-button-with-short-label > .monaco-button-label-short {
  width: 0;
  flex-grow: 1;
  overflow: hidden;
}

.monaco-text-button.monaco-text-button-with-short-label > .monaco-button-label, .monaco-text-button.monaco-text-button-with-short-label > .monaco-button-label-short {
  font-weight: normal;
  font-style: inherit;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  display: flex;
}

.monaco-button-dropdown {
  cursor: pointer;
  display: flex;
}

.monaco-button-dropdown.disabled {
  cursor: default;
}

.monaco-button-dropdown > .monaco-button:focus {
  outline-offset: -1px !important;
}

.monaco-button-dropdown.disabled > .monaco-button.disabled, .monaco-button-dropdown.disabled > .monaco-button.disabled:focus, .monaco-button-dropdown.disabled > .monaco-button-dropdown-separator {
  opacity: .4 !important;
}

.monaco-button-dropdown > .monaco-button.monaco-text-button {
  border-right-width: 0 !important;
}

.monaco-button-dropdown .monaco-button-dropdown-separator {
  cursor: default;
  padding: 4px 0;
}

.monaco-button-dropdown .monaco-button-dropdown-separator > div {
  height: 100%;
  width: 1px;
}

.monaco-button-dropdown > .monaco-button.monaco-dropdown-button {
  border: 1px solid var(--vscode-button-border, transparent);
  border-radius: 0 2px 2px 0;
  border-left-width: 0 !important;
}

.monaco-button-dropdown > .monaco-button.monaco-text-button {
  border-radius: 2px 0 0 2px;
}

.monaco-description-button {
  flex-direction: column;
  align-items: center;
  margin: 4px 5px;
  display: flex;
}

.monaco-description-button .monaco-button-description {
  padding: 4px 20px;
  font-size: 11px;
  font-style: italic;
}

.monaco-description-button .monaco-button-label, .monaco-description-button .monaco-button-description {
  justify-content: center;
  align-items: center;
  display: flex;
}

.monaco-description-button .monaco-button-label > .codicon, .monaco-description-button .monaco-button-description > .codicon {
  margin: 0 .2em;
  color: inherit !important;
}

.monaco-count-badge {
  min-width: 18px;
  min-height: 18px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 11px;
  padding: 3px 6px;
  font-size: 11px;
  font-weight: normal;
  line-height: 11px;
  display: inline-block;
}

.monaco-count-badge.long {
  min-height: auto;
  border-radius: 2px;
  padding: 2px 3px;
  line-height: normal;
}

.monaco-progress-container {
  width: 100%;
  height: 5px;
  overflow: hidden;
}

.monaco-progress-container .progress-bit {
  width: 2%;
  height: 5px;
  display: none;
  position: absolute;
  left: 0;
}

.monaco-progress-container.active .progress-bit {
  display: inherit;
}

.monaco-progress-container.discrete .progress-bit {
  transition: width .1s linear;
  left: 0;
}

.monaco-progress-container.discrete.done .progress-bit {
  width: 100%;
}

.monaco-progress-container.infinite .progress-bit {
  animation-name: progress;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform: translate3d(0, 0, 0);
}

.monaco-progress-container.infinite.infinite-long-running .progress-bit {
  animation-timing-function: steps(100, end);
}

@keyframes progress {
  from {
    transform: translateX(0%)scaleX(1);
  }

  50% {
    transform: translateX(2500%)scaleX(3);
  }

  to {
    transform: translateX(4900%)scaleX(1);
  }
}

.quick-input-widget {
  width: 600px;
  z-index: 2550;
  -webkit-app-region: no-drag;
  border-radius: 6px;
  margin-left: -300px;
  position: absolute;
  left: 50%;
}

.quick-input-titlebar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  align-items: center;
  display: flex;
}

.quick-input-left-action-bar {
  flex: 1;
  margin-left: 4px;
  display: flex;
}

.quick-input-title {
  text-align: center;
  text-overflow: ellipsis;
  padding: 3px 0;
  overflow: hidden;
}

.quick-input-right-action-bar {
  flex: 1;
  margin-right: 4px;
  display: flex;
}

.quick-input-right-action-bar > .actions-container {
  justify-content: flex-end;
}

.quick-input-titlebar .monaco-action-bar .action-label.codicon {
  background-position: center;
  background-repeat: no-repeat;
  padding: 2px;
}

.quick-input-description {
  margin: 6px;
}

.quick-input-header .quick-input-description {
  margin: 4px 2px;
}

.quick-input-header {
  padding: 8px 6px 6px;
  display: flex;
}

.quick-input-widget.hidden-input .quick-input-header {
  margin-bottom: 0;
  padding: 0;
}

.quick-input-and-message {
  min-width: 0;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  position: relative;
}

.quick-input-check-all {
  align-self: center;
  margin: 0;
}

.quick-input-filter {
  flex-grow: 1;
  display: flex;
  position: relative;
}

.quick-input-box {
  flex-grow: 1;
}

.quick-input-widget.show-checkboxes .quick-input-box, .quick-input-widget.show-checkboxes .quick-input-message {
  margin-left: 5px;
}

.quick-input-visible-count {
  position: absolute;
  left: -10000px;
}

.quick-input-count {
  align-self: center;
  align-items: center;
  display: flex;
  position: absolute;
  right: 4px;
}

.quick-input-count .monaco-count-badge {
  vertical-align: middle;
  min-height: auto;
  border-radius: 2px;
  padding: 2px 4px;
  line-height: normal;
}

.quick-input-action {
  margin-left: 6px;
}

.quick-input-action .monaco-text-button {
  height: 25px;
  align-items: center;
  padding: 0 6px;
  font-size: 11px;
  display: flex;
}

.quick-input-message {
  overflow-wrap: break-word;
  margin-top: -1px;
  padding: 5px;
}

.quick-input-message > .codicon {
  vertical-align: text-bottom;
  margin: 0 .2em;
}

.quick-input-message a {
  color: inherit;
}

.quick-input-progress.monaco-progress-container {
  position: relative;
}

.quick-input-progress.monaco-progress-container, .quick-input-progress.monaco-progress-container .progress-bit {
  height: 2px;
}

.quick-input-list {
  line-height: 22px;
}

.quick-input-widget.hidden-input .quick-input-list {
  margin-top: 4px;
  padding-bottom: 4px;
}

.quick-input-list .monaco-list {
  max-height: 440px;
  padding-bottom: 5px;
  overflow: hidden;
}

.quick-input-list .monaco-scrollable-element {
  padding: 0 5px;
}

.quick-input-list .quick-input-list-entry {
  box-sizing: border-box;
  height: 100%;
  padding: 0 6px;
  display: flex;
  overflow: hidden;
}

.quick-input-list .quick-input-list-entry.quick-input-list-separator-border {
  border-top-style: solid;
  border-top-width: 1px;
}

.quick-input-list .monaco-list-row {
  border-radius: 3px;
}

.quick-input-list .monaco-list-row[data-index="0"] .quick-input-list-entry.quick-input-list-separator-border {
  border-top-style: none;
}

.quick-input-list .quick-input-list-label {
  height: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.quick-input-list .quick-input-list-checkbox {
  align-self: center;
  margin: 0;
}

.quick-input-list .quick-input-list-rows {
  text-overflow: ellipsis;
  height: 100%;
  flex-direction: column;
  flex: 1;
  margin-left: 5px;
  display: flex;
  overflow: hidden;
}

.quick-input-widget.show-checkboxes .quick-input-list .quick-input-list-rows {
  margin-left: 10px;
}

.quick-input-widget .quick-input-list .quick-input-list-checkbox {
  display: none;
}

.quick-input-widget.show-checkboxes .quick-input-list .quick-input-list-checkbox {
  display: inline;
}

.quick-input-list .quick-input-list-rows > .quick-input-list-row {
  align-items: center;
  display: flex;
}

.quick-input-list .quick-input-list-rows > .quick-input-list-row .monaco-icon-label, .quick-input-list .quick-input-list-rows > .quick-input-list-row .monaco-icon-label .monaco-icon-label-container > .monaco-icon-name-container {
  flex: 1;
}

.quick-input-list .quick-input-list-rows > .quick-input-list-row .codicon[class*="codicon-"] {
  vertical-align: text-bottom;
}

.quick-input-list .quick-input-list-rows .monaco-highlighted-label > span {
  opacity: 1;
}

.quick-input-list .quick-input-list-entry .quick-input-list-entry-keybinding {
  margin-right: 8px;
}

.quick-input-list .quick-input-list-label-meta {
  opacity: .7;
  text-overflow: ellipsis;
  line-height: normal;
  overflow: hidden;
}

.quick-input-list .monaco-highlighted-label .highlight {
  font-weight: bold;
}

.quick-input-list .quick-input-list-entry .quick-input-list-separator {
  margin-right: 4px;
}

.quick-input-list .quick-input-list-entry-action-bar {
  flex: 0;
  display: flex;
  overflow: visible;
}

.quick-input-list .quick-input-list-entry-action-bar .action-label {
  display: none;
}

.quick-input-list .quick-input-list-entry-action-bar .action-label.codicon {
  margin-right: 4px;
  padding: 0 2px 2px;
}

.quick-input-list .quick-input-list-entry-action-bar {
  margin-top: 1px;
  margin-right: 4px;
}

.quick-input-list .quick-input-list-entry .quick-input-list-entry-action-bar .action-label.always-visible, .quick-input-list .quick-input-list-entry:hover .quick-input-list-entry-action-bar .action-label, .quick-input-list .monaco-list-row.focused .quick-input-list-entry-action-bar .action-label {
  display: flex;
}

.quick-input-list .monaco-list-row.focused .monaco-keybinding-key, .quick-input-list .monaco-list-row.focused .quick-input-list-entry .quick-input-list-separator {
  color: inherit;
}

.quick-input-list .monaco-list-row.focused .monaco-keybinding-key {
  background: none;
}

.monaco-icon-label {
  text-overflow: ellipsis;
  display: flex;
  overflow: hidden;
}

.monaco-icon-label:before {
  width: 16px;
  height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 16px;
  flex-shrink: 0;
  padding-right: 6px;
  display: inline-block;
  line-height: inherit !important;
}

.monaco-icon-label-container.disabled {
  color: var(--vscode-disabledForeground);
}

.monaco-icon-label > .monaco-icon-label-container {
  min-width: 0;
  text-overflow: ellipsis;
  flex: 1;
  overflow: hidden;
}

.monaco-icon-label > .monaco-icon-label-container > .monaco-icon-name-container > .label-name {
  color: inherit;
  white-space: pre;
}

.monaco-icon-label > .monaco-icon-label-container > .monaco-icon-name-container > .label-name > .label-separator {
  opacity: .5;
  margin: 0 2px;
}

.monaco-icon-label > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  opacity: .7;
  white-space: pre;
  margin-left: .5em;
  font-size: .9em;
}

.monaco-icon-label.nowrap > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  white-space: nowrap;
}

.vs .monaco-icon-label > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  opacity: .95;
}

.monaco-icon-label.italic > .monaco-icon-label-container > .monaco-icon-name-container > .label-name, .monaco-icon-label.italic > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  font-style: italic;
}

.monaco-icon-label.deprecated {
  opacity: .66;
  text-decoration: line-through;
}

.monaco-icon-label.italic:after {
  font-style: italic;
}

.monaco-icon-label.strikethrough > .monaco-icon-label-container > .monaco-icon-name-container > .label-name, .monaco-icon-label.strikethrough > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  text-decoration: line-through;
}

.monaco-icon-label:after {
  opacity: .75;
  text-align: center;
  margin: auto 16px 0 5px;
  font-size: 90%;
  font-weight: 600;
}

.monaco-list:focus .selected .monaco-icon-label, .monaco-list:focus .selected .monaco-icon-label:after {
  color: inherit !important;
}

.monaco-list-row.focused.selected .label-description, .monaco-list-row.selected .label-description {
  opacity: .8;
}

.monaco-keybinding {
  align-items: center;
  line-height: 10px;
  display: flex;
}

.monaco-keybinding > .monaco-keybinding-key {
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  margin: 0 2px;
  padding: 3px 5px;
  font-size: 11px;
  display: inline-block;
}

.monaco-keybinding > .monaco-keybinding-key:first-child {
  margin-left: 0;
}

.monaco-keybinding > .monaco-keybinding-key:last-child {
  margin-right: 0;
}

.monaco-keybinding > .monaco-keybinding-key-separator {
  display: inline-block;
}

.monaco-keybinding > .monaco-keybinding-key-chord-separator {
  width: 6px;
}

.monaco-editor .selection-anchor {
  background-color: #007acc;
  width: 2px !important;
}

.monaco-editor .bracket-match {
  box-sizing: border-box;
  background-color: var(--vscode-editorBracketMatch-background);
  border: 1px solid var(--vscode-editorBracketMatch-border);
}

@font-face {
  font-family: codicon;
  font-display: block;
  src: url("codicon.0b53d7f4.ttf") format("truetype");
}

.codicon[class*="codicon-"] {
  text-rendering: auto;
  text-align: center;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  user-select: none;
  font: 16px / 1 codicon;
  text-decoration: none;
  display: inline-block;
}

.codicon-wrench-subaction {
  opacity: .5;
}

@keyframes codicon-spin {
  100% {
    transform: rotate(360deg);
  }
}

.codicon-sync.codicon-modifier-spin, .codicon-loading.codicon-modifier-spin, .codicon-gear.codicon-modifier-spin, .codicon-notebook-state-executing.codicon-modifier-spin {
  animation: 1.5s steps(30, end) infinite codicon-spin;
}

.codicon-modifier-disabled {
  opacity: .4;
}

.codicon-loading, .codicon-tree-item-loading:before {
  animation-duration: 1s !important;
  animation-timing-function: cubic-bezier(.53, .21, .29, .67) !important;
}

.monaco-editor .codicon.codicon-symbol-array, .monaco-workbench .codicon.codicon-symbol-array {
  color: var(--vscode-symbolIcon-arrayForeground);
}

.monaco-editor .codicon.codicon-symbol-boolean, .monaco-workbench .codicon.codicon-symbol-boolean {
  color: var(--vscode-symbolIcon-booleanForeground);
}

.monaco-editor .codicon.codicon-symbol-class, .monaco-workbench .codicon.codicon-symbol-class {
  color: var(--vscode-symbolIcon-classForeground);
}

.monaco-editor .codicon.codicon-symbol-method, .monaco-workbench .codicon.codicon-symbol-method {
  color: var(--vscode-symbolIcon-methodForeground);
}

.monaco-editor .codicon.codicon-symbol-color, .monaco-workbench .codicon.codicon-symbol-color {
  color: var(--vscode-symbolIcon-colorForeground);
}

.monaco-editor .codicon.codicon-symbol-constant, .monaco-workbench .codicon.codicon-symbol-constant {
  color: var(--vscode-symbolIcon-constantForeground);
}

.monaco-editor .codicon.codicon-symbol-constructor, .monaco-workbench .codicon.codicon-symbol-constructor {
  color: var(--vscode-symbolIcon-constructorForeground);
}

.monaco-editor .codicon.codicon-symbol-value, .monaco-workbench .codicon.codicon-symbol-value, .monaco-editor .codicon.codicon-symbol-enum, .monaco-workbench .codicon.codicon-symbol-enum {
  color: var(--vscode-symbolIcon-enumeratorForeground);
}

.monaco-editor .codicon.codicon-symbol-enum-member, .monaco-workbench .codicon.codicon-symbol-enum-member {
  color: var(--vscode-symbolIcon-enumeratorMemberForeground);
}

.monaco-editor .codicon.codicon-symbol-event, .monaco-workbench .codicon.codicon-symbol-event {
  color: var(--vscode-symbolIcon-eventForeground);
}

.monaco-editor .codicon.codicon-symbol-field, .monaco-workbench .codicon.codicon-symbol-field {
  color: var(--vscode-symbolIcon-fieldForeground);
}

.monaco-editor .codicon.codicon-symbol-file, .monaco-workbench .codicon.codicon-symbol-file {
  color: var(--vscode-symbolIcon-fileForeground);
}

.monaco-editor .codicon.codicon-symbol-folder, .monaco-workbench .codicon.codicon-symbol-folder {
  color: var(--vscode-symbolIcon-folderForeground);
}

.monaco-editor .codicon.codicon-symbol-function, .monaco-workbench .codicon.codicon-symbol-function {
  color: var(--vscode-symbolIcon-functionForeground);
}

.monaco-editor .codicon.codicon-symbol-interface, .monaco-workbench .codicon.codicon-symbol-interface {
  color: var(--vscode-symbolIcon-interfaceForeground);
}

.monaco-editor .codicon.codicon-symbol-key, .monaco-workbench .codicon.codicon-symbol-key {
  color: var(--vscode-symbolIcon-keyForeground);
}

.monaco-editor .codicon.codicon-symbol-keyword, .monaco-workbench .codicon.codicon-symbol-keyword {
  color: var(--vscode-symbolIcon-keywordForeground);
}

.monaco-editor .codicon.codicon-symbol-module, .monaco-workbench .codicon.codicon-symbol-module {
  color: var(--vscode-symbolIcon-moduleForeground);
}

.monaco-editor .codicon.codicon-symbol-namespace, .monaco-workbench .codicon.codicon-symbol-namespace {
  color: var(--vscode-symbolIcon-namespaceForeground);
}

.monaco-editor .codicon.codicon-symbol-null, .monaco-workbench .codicon.codicon-symbol-null {
  color: var(--vscode-symbolIcon-nullForeground);
}

.monaco-editor .codicon.codicon-symbol-number, .monaco-workbench .codicon.codicon-symbol-number {
  color: var(--vscode-symbolIcon-numberForeground);
}

.monaco-editor .codicon.codicon-symbol-object, .monaco-workbench .codicon.codicon-symbol-object {
  color: var(--vscode-symbolIcon-objectForeground);
}

.monaco-editor .codicon.codicon-symbol-operator, .monaco-workbench .codicon.codicon-symbol-operator {
  color: var(--vscode-symbolIcon-operatorForeground);
}

.monaco-editor .codicon.codicon-symbol-package, .monaco-workbench .codicon.codicon-symbol-package {
  color: var(--vscode-symbolIcon-packageForeground);
}

.monaco-editor .codicon.codicon-symbol-property, .monaco-workbench .codicon.codicon-symbol-property {
  color: var(--vscode-symbolIcon-propertyForeground);
}

.monaco-editor .codicon.codicon-symbol-reference, .monaco-workbench .codicon.codicon-symbol-reference {
  color: var(--vscode-symbolIcon-referenceForeground);
}

.monaco-editor .codicon.codicon-symbol-snippet, .monaco-workbench .codicon.codicon-symbol-snippet {
  color: var(--vscode-symbolIcon-snippetForeground);
}

.monaco-editor .codicon.codicon-symbol-string, .monaco-workbench .codicon.codicon-symbol-string {
  color: var(--vscode-symbolIcon-stringForeground);
}

.monaco-editor .codicon.codicon-symbol-struct, .monaco-workbench .codicon.codicon-symbol-struct {
  color: var(--vscode-symbolIcon-structForeground);
}

.monaco-editor .codicon.codicon-symbol-text, .monaco-workbench .codicon.codicon-symbol-text {
  color: var(--vscode-symbolIcon-textForeground);
}

.monaco-editor .codicon.codicon-symbol-type-parameter, .monaco-workbench .codicon.codicon-symbol-type-parameter {
  color: var(--vscode-symbolIcon-typeParameterForeground);
}

.monaco-editor .codicon.codicon-symbol-unit, .monaco-workbench .codicon.codicon-symbol-unit {
  color: var(--vscode-symbolIcon-unitForeground);
}

.monaco-editor .codicon.codicon-symbol-variable, .monaco-workbench .codicon.codicon-symbol-variable {
  color: var(--vscode-symbolIcon-variableForeground);
}

.monaco-editor .monaco-editor-overlaymessage {
  z-index: 10000;
  padding-bottom: 8px;
}

.monaco-editor .monaco-editor-overlaymessage.below {
  z-index: 10000;
  padding-top: 8px;
  padding-bottom: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.monaco-editor .monaco-editor-overlaymessage.fadeIn {
  animation: .15s ease-out fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.monaco-editor .monaco-editor-overlaymessage.fadeOut {
  animation: .1s ease-out fadeOut;
}

.monaco-editor .monaco-editor-overlaymessage .message {
  color: var(--vscode-inputValidation-infoForeground);
  background-color: var(--vscode-inputValidation-infoBackground);
  border: 1px solid var(--vscode-inputValidation-infoBorder);
  padding: 1px 4px;
}

.monaco-editor.hc-black .monaco-editor-overlaymessage .message, .monaco-editor.hc-light .monaco-editor-overlaymessage .message {
  border-width: 2px;
}

.monaco-editor .monaco-editor-overlaymessage .anchor {
  z-index: 1000;
  border: 8px solid #0000;
  position: absolute;
  width: 0 !important;
  height: 0 !important;
}

.monaco-editor .monaco-editor-overlaymessage .anchor.top {
  border-bottom-color: var(--vscode-inputValidation-infoBorder);
}

.monaco-editor .monaco-editor-overlaymessage .anchor.below {
  border-top-color: var(--vscode-inputValidation-infoBorder);
}

.monaco-editor .monaco-editor-overlaymessage:not(.below) .anchor.top, .monaco-editor .monaco-editor-overlaymessage.below .anchor.below {
  display: none;
}

.monaco-editor .monaco-editor-overlaymessage.below .anchor.top {
  display: inherit;
  top: -8px;
}

.action-widget {
  min-width: 160px;
  max-width: 500px;
  z-index: 40;
  width: 100%;
  background-color: var(--vscode-editorWidget-background);
  color: var(--vscode-editorWidget-foreground);
  border-radius: 0;
  font-size: 13px;
  display: block;
  border: 1px solid var(--vscode-editorWidget-border) !important;
}

.context-view-block {
  cursor: initial;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
}

.context-view-pointerBlock {
  cursor: initial;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
}

.action-widget .monaco-list {
  -webkit-user-select: none;
  user-select: none;
  border: 0 !important;
}

.action-widget .monaco-list:focus:before {
  outline: 0 !important;
}

.action-widget .monaco-list .monaco-scrollable-element {
  overflow: visible;
}

.action-widget .monaco-list .monaco-list-row {
  white-space: nowrap;
  cursor: pointer;
  touch-action: none;
  width: 100%;
  padding: 0 10px;
}

.action-widget .monaco-list .monaco-list-row.action.focused:not(.option-disabled) {
  color: var(--vscode-quickInputList-focusForeground);
  outline: 1px solid var(--vscode-menu-selectionBorder, transparent);
  outline-offset: -1px;
  background-color: var(--vscode-quickInputList-focusBackground) !important;
}

.action-widget .monaco-list-row.group-header {
  font-weight: 600;
  color: var(--vscode-pickerGroup-foreground) !important;
}

.action-widget .monaco-list .group-header, .action-widget .monaco-list .option-disabled, .action-widget .monaco-list .option-disabled:before, .action-widget .monaco-list .option-disabled .focused, .action-widget .monaco-list .option-disabled .focused:before {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: default !important;
  background-color: #0000 !important;
  outline: 0 solid !important;
}

.action-widget .monaco-list-row.action {
  align-items: center;
  gap: 6px;
  display: flex;
}

.action-widget .monaco-list-row.action.option-disabled {
  color: var(--vscode-disabledForeground);
}

.action-widget .monaco-list-row.action.option-disabled .codicon {
  opacity: .4;
}

.action-widget .monaco-list-row.action:not(.option-disabled) .codicon {
  color: inherit;
}

.action-widget .monaco-list-row.action .title {
  text-overflow: ellipsis;
  flex: 1;
  overflow: hidden;
}

.action-widget .action-widget-action-bar {
  background-color: var(--vscode-editorHoverWidget-statusBarBackground);
  border-top: 1px solid var(--vscode-editorHoverWidget-border);
}

.action-widget .action-widget-action-bar:before {
  content: "";
  width: 100%;
  display: block;
}

.action-widget .action-widget-action-bar .actions-container {
  padding: 0 8px;
}

.action-widget-action-bar .action-label {
  color: var(--vscode-textLink-activeForeground);
  pointer-events: all;
  padding: 0;
  font-size: 12px;
  line-height: 22px;
}

.action-widget-action-bar .action-item {
  pointer-events: none;
  margin-right: 16px;
}

.action-widget-action-bar .action-label:hover {
  background-color: #0000 !important;
}

.monaco-editor .lightBulbWidget {
  justify-content: center;
  align-items: center;
  display: flex;
}

.monaco-editor .lightBulbWidget:hover {
  cursor: pointer;
}

.monaco-editor .lightBulbWidget.codicon-light-bulb {
  color: var(--vscode-editorLightBulb-foreground);
}

.monaco-editor .lightBulbWidget.codicon-lightbulb-autofix {
  color: var(--vscode-editorLightBulbAutoFix-foreground, var(--vscode-editorLightBulb-foreground));
}

.monaco-editor .lightBulbWidget:before {
  z-index: 2;
  position: relative;
}

.monaco-editor .lightBulbWidget:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .3;
  background-color: var(--vscode-editor-background);
  z-index: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-editor .codelens-decoration {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--vscode-editorCodeLens-foreground);
  line-height: var(--vscode-editorCodeLens-lineHeight);
  font-size: var(--vscode-editorCodeLens-fontSize);
  padding-right: calc(var(--vscode-editorCodeLens-fontSize) * .5);
  font-feature-settings: var(--vscode-editorCodeLens-fontFeatureSettings);
  font-family: var(--vscode-editorCodeLens-fontFamily), var(--vscode-editorCodeLens-fontFamilyDefault);
  display: inline-block;
  overflow: hidden;
}

.monaco-editor .codelens-decoration > span, .monaco-editor .codelens-decoration > a {
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  vertical-align: sub;
}

.monaco-editor .codelens-decoration > a {
  text-decoration: none;
}

.monaco-editor .codelens-decoration > a:hover {
  cursor: pointer;
  color: var(--vscode-editorLink-activeForeground) !important;
}

.monaco-editor .codelens-decoration > a:hover .codicon {
  color: var(--vscode-editorLink-activeForeground) !important;
}

.monaco-editor .codelens-decoration .codicon {
  vertical-align: middle;
  color: var(--vscode-editorCodeLens-foreground);
  line-height: var(--vscode-editorCodeLens-lineHeight);
  font-size: var(--vscode-editorCodeLens-fontSize);
  color: currentColor !important;
}

.monaco-editor .codelens-decoration > a:hover .codicon:before {
  cursor: pointer;
}

@keyframes fadein {
  0% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
  }
}

.monaco-editor .codelens-decoration.fadein {
  animation: .1s linear fadein;
}

.colorpicker-widget {
  height: 190px;
  -webkit-user-select: none;
  user-select: none;
}

.colorpicker-color-decoration, .hc-light .colorpicker-color-decoration {
  box-sizing: border-box;
  width: .8em;
  height: .8em;
  cursor: pointer;
  border: .1em solid #000;
  margin: .1em .2em 0;
  line-height: .8em;
  display: inline-block;
}

.hc-black .colorpicker-color-decoration, .vs-dark .colorpicker-color-decoration {
  border: .1em solid #eee;
}

.colorpicker-header {
  height: 24px;
  image-rendering: pixelated;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTZEaa/1AAAAHUlEQVQYV2PYvXu3JAi7uLiAMaYAjAGTQBPYLQkAa/0Zef3qRswAAAAASUVORK5CYII=") 0 0 / 9px 9px;
  display: flex;
  position: relative;
}

.colorpicker-header .picked-color {
  width: 216px;
  cursor: pointer;
  color: #fff;
  flex: 1;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  display: flex;
}

.colorpicker-header .picked-color .codicon {
  color: inherit;
  font-size: 14px;
  position: absolute;
  left: 8px;
}

.colorpicker-header .picked-color.light {
  color: #000;
}

.colorpicker-header .original-color {
  width: 74px;
  z-index: inherit;
  cursor: pointer;
}

.colorpicker-body {
  padding: 8px;
  display: flex;
  position: relative;
}

.colorpicker-body .saturation-wrap {
  height: 150px;
  min-width: 220px;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.colorpicker-body .saturation-box {
  height: 150px;
  position: absolute;
}

.colorpicker-body .saturation-selection {
  width: 9px;
  height: 9px;
  border: 1px solid #fff;
  border-radius: 100%;
  margin: -5px 0 0 -5px;
  position: absolute;
  box-shadow: 0 0 2px #000c;
}

.colorpicker-body .strip {
  width: 25px;
  height: 150px;
}

.colorpicker-body .hue-strip {
  cursor: grab;
  background: linear-gradient(red 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red 100%);
  margin-left: 8px;
  position: relative;
}

.colorpicker-body .opacity-strip {
  cursor: grab;
  image-rendering: pixelated;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTZEaa/1AAAAHUlEQVQYV2PYvXu3JAi7uLiAMaYAjAGTQBPYLQkAa/0Zef3qRswAAAAASUVORK5CYII=") 0 0 / 9px 9px;
  margin-left: 8px;
  position: relative;
}

.colorpicker-body .strip.grabbing {
  cursor: grabbing;
}

.colorpicker-body .slider {
  width: calc(100% + 4px);
  height: 4px;
  box-sizing: border-box;
  border: 1px solid #ffffffb5;
  position: absolute;
  top: 0;
  left: -2px;
  box-shadow: 0 0 1px #000000d9;
}

.colorpicker-body .strip .overlay {
  height: 150px;
  pointer-events: none;
}

.monaco-editor .goto-definition-link {
  cursor: pointer;
  text-decoration: underline;
  color: var(--vscode-editorLink-activeForeground) !important;
}

.monaco-editor .peekview-widget .head {
  box-sizing: border-box;
  flex-wrap: nowrap;
  justify-content: space-between;
  display: flex;
}

.monaco-editor .peekview-widget .head .peekview-title {
  min-width: 0;
  text-overflow: ellipsis;
  align-items: baseline;
  margin-left: 20px;
  font-size: 13px;
  display: flex;
  overflow: hidden;
}

.monaco-editor .peekview-widget .head .peekview-title.clickable {
  cursor: pointer;
}

.monaco-editor .peekview-widget .head .peekview-title .dirname:not(:empty) {
  margin-left: .5em;
  font-size: .9em;
}

.monaco-editor .peekview-widget .head .peekview-title .meta {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.monaco-editor .peekview-widget .head .peekview-title .dirname, .monaco-editor .peekview-widget .head .peekview-title .filename {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.monaco-editor .peekview-widget .head .peekview-title .meta:not(:empty):before {
  content: "-";
  padding: 0 .3em;
}

.monaco-editor .peekview-widget .head .peekview-actions {
  text-align: right;
  flex: 1;
  padding-right: 2px;
}

.monaco-editor .peekview-widget .head .peekview-actions > .monaco-action-bar {
  display: inline-block;
}

.monaco-editor .peekview-widget .head .peekview-actions > .monaco-action-bar, .monaco-editor .peekview-widget .head .peekview-actions > .monaco-action-bar > .actions-container {
  height: 100%;
}

.monaco-editor .peekview-widget > .body {
  border-top: 1px solid;
  position: relative;
}

.monaco-editor .peekview-widget .head .peekview-title .codicon {
  align-self: center;
  margin-right: 4px;
}

.monaco-editor .peekview-widget .monaco-list .monaco-list-row.focused .codicon {
  color: inherit !important;
}

.monaco-editor .zone-widget {
  z-index: 10;
  position: absolute;
}

.monaco-editor .zone-widget .zone-widget-container {
  border-top-style: solid;
  border-top-width: 0;
  border-bottom-style: solid;
  border-bottom-width: 0;
  position: relative;
}

.monaco-editor .zone-widget .zone-widget-container.reference-zone-widget {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.monaco-editor .reference-zone-widget .inline {
  vertical-align: top;
  display: inline-block;
}

.monaco-editor .reference-zone-widget .messages {
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 3em 0;
}

.monaco-editor .reference-zone-widget .ref-tree {
  background-color: var(--vscode-peekViewResult-background);
  color: var(--vscode-peekViewResult-lineForeground);
  line-height: 23px;
}

.monaco-editor .reference-zone-widget .ref-tree .reference {
  text-overflow: ellipsis;
  overflow: hidden;
}

.monaco-editor .reference-zone-widget .ref-tree .reference-file {
  width: 100%;
  height: 100%;
  color: var(--vscode-peekViewResult-fileForeground);
  display: inline-flex;
}

.monaco-editor .reference-zone-widget .ref-tree .monaco-list:focus .selected .reference-file {
  color: inherit !important;
}

.monaco-editor .reference-zone-widget .ref-tree .monaco-list:focus .monaco-list-rows > .monaco-list-row.selected:not(.highlighted) {
  background-color: var(--vscode-peekViewResult-selectionBackground);
  color: var(--vscode-peekViewResult-selectionForeground) !important;
}

.monaco-editor .reference-zone-widget .ref-tree .reference-file .count {
  margin-left: auto;
  margin-right: 12px;
}

.monaco-editor .reference-zone-widget .ref-tree .referenceMatch .highlight {
  background-color: var(--vscode-peekViewResult-matchHighlightBackground);
}

.monaco-editor .reference-zone-widget .preview .reference-decoration {
  background-color: var(--vscode-peekViewEditor-matchHighlightBackground);
  border: 2px solid var(--vscode-peekViewEditor-matchHighlightBorder);
  box-sizing: border-box;
}

.monaco-editor .reference-zone-widget .preview .monaco-editor .monaco-editor-background, .monaco-editor .reference-zone-widget .preview .monaco-editor .inputarea.ime-input {
  background-color: var(--vscode-peekViewEditor-background);
}

.monaco-editor .reference-zone-widget .preview .monaco-editor .margin {
  background-color: var(--vscode-peekViewEditorGutter-background);
}

.monaco-editor.hc-black .reference-zone-widget .ref-tree .reference-file, .monaco-editor.hc-light .reference-zone-widget .ref-tree .reference-file {
  font-weight: bold;
}

.monaco-editor.hc-black .reference-zone-widget .ref-tree .referenceMatch .highlight, .monaco-editor.hc-light .reference-zone-widget .ref-tree .referenceMatch .highlight {
  border: 1px dotted var(--vscode-contrastActiveBorder, transparent);
  box-sizing: border-box;
}

.monaco-hover {
  cursor: default;
  z-index: 50;
  -webkit-user-select: text;
  user-select: text;
  box-sizing: initial;
  line-height: 1.5em;
  animation: .1s linear fadein;
  position: absolute;
  overflow: hidden;
}

.monaco-hover.hidden {
  display: none;
}

.monaco-hover a:hover:not(.disabled) {
  cursor: pointer;
}

.monaco-hover .hover-contents:not(.html-hover-contents) {
  padding: 4px 8px;
}

.monaco-hover .markdown-hover > .hover-contents:not(.code-hover-contents) {
  max-width: 500px;
  word-wrap: break-word;
}

.monaco-hover .markdown-hover > .hover-contents:not(.code-hover-contents) hr {
  min-width: 100%;
}

.monaco-hover p, .monaco-hover .code, .monaco-hover ul, .monaco-hover h1, .monaco-hover h2, .monaco-hover h3, .monaco-hover h4, .monaco-hover h5, .monaco-hover h6 {
  margin: 8px 0;
}

.monaco-hover h1, .monaco-hover h2, .monaco-hover h3, .monaco-hover h4, .monaco-hover h5, .monaco-hover h6 {
  line-height: 1.1;
}

.monaco-hover code {
  font-family: var(--monaco-monospace-font);
}

.monaco-hover hr {
  box-sizing: border-box;
  height: 1px;
  border-left: 0;
  border-right: 0;
  margin: 4px -8px -4px;
}

.monaco-hover p:first-child, .monaco-hover .code:first-child, .monaco-hover ul:first-child {
  margin-top: 0;
}

.monaco-hover p:last-child, .monaco-hover .code:last-child, .monaco-hover ul:last-child {
  margin-bottom: 0;
}

.monaco-hover ul, .monaco-hover ol {
  padding-left: 20px;
}

.monaco-hover li > p {
  margin-bottom: 0;
}

.monaco-hover li > ul {
  margin-top: 0;
}

.monaco-hover code {
  border-radius: 3px;
  padding: 0 .4em;
}

.monaco-hover .monaco-tokenized-source {
  white-space: pre-wrap;
}

.monaco-hover .hover-row.status-bar {
  font-size: 12px;
  line-height: 22px;
}

.monaco-hover .hover-row.status-bar .info {
  padding: 0 8px;
  font-style: italic;
}

.monaco-hover .hover-row.status-bar .actions {
  padding: 0 8px;
  display: flex;
}

.monaco-hover .hover-row.status-bar .actions .action-container {
  cursor: pointer;
  margin-right: 16px;
}

.monaco-hover .hover-row.status-bar .actions .action-container .action .icon {
  padding-right: 4px;
}

.monaco-hover .markdown-hover .hover-contents .codicon {
  color: inherit;
  font-size: inherit;
  vertical-align: middle;
}

.monaco-hover .hover-contents a.code-link:hover, .monaco-hover .hover-contents a.code-link {
  color: inherit;
}

.monaco-hover .hover-contents a.code-link:before {
  content: "(";
}

.monaco-hover .hover-contents a.code-link:after {
  content: ")";
}

.monaco-hover .hover-contents a.code-link > span {
  text-underline-position: under;
  color: var(--vscode-textLink-foreground);
  border-bottom: 1px solid #0000;
  text-decoration: underline;
}

.monaco-hover .hover-contents a.code-link > span:hover {
  color: var(--vscode-textLink-activeForeground);
}

.monaco-hover .markdown-hover .hover-contents:not(.code-hover-contents):not(.html-hover-contents) span {
  margin-bottom: 4px;
  display: inline-block;
}

.monaco-hover-content .action-container a {
  -webkit-user-select: none;
  user-select: none;
}

.monaco-hover-content .action-container.disabled {
  pointer-events: none;
  opacity: .4;
  cursor: default;
}

.monaco-editor .rendered-markdown kbd {
  background-color: var(--vscode-keybindingLabel-background);
  color: var(--vscode-keybindingLabel-foreground);
  border-style: solid;
  border-width: 1px;
  border-color: var(--vscode-keybindingLabel-border);
  border-bottom-color: var(--vscode-keybindingLabel-bottomBorder);
  box-shadow: inset 0 -1px 0 var(--vscode-widget-shadow);
  vertical-align: middle;
  border-radius: 3px;
  padding: 1px 3px;
}

.monaco-editor .peekview-widget .head .peekview-title .severity-icon {
  vertical-align: text-top;
  margin-right: 4px;
  display: inline-block;
}

.monaco-editor .marker-widget {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.monaco-editor .marker-widget > .stale {
  opacity: .6;
  font-style: italic;
}

.monaco-editor .marker-widget .title {
  padding-right: 5px;
  display: inline-block;
}

.monaco-editor .marker-widget .descriptioncontainer {
  white-space: pre;
  -webkit-user-select: text;
  user-select: text;
  padding: 8px 12px 0 20px;
  position: absolute;
}

.monaco-editor .marker-widget .descriptioncontainer .message {
  flex-direction: column;
  display: flex;
}

.monaco-editor .marker-widget .descriptioncontainer .message .details {
  padding-left: 6px;
}

.monaco-editor .marker-widget .descriptioncontainer .message .source, .monaco-editor .marker-widget .descriptioncontainer .message span.code {
  opacity: .6;
}

.monaco-editor .marker-widget .descriptioncontainer .message a.code-link {
  opacity: .6;
  color: inherit;
}

.monaco-editor .marker-widget .descriptioncontainer .message a.code-link:before {
  content: "(";
}

.monaco-editor .marker-widget .descriptioncontainer .message a.code-link:after {
  content: ")";
}

.monaco-editor .marker-widget .descriptioncontainer .message a.code-link > span {
  text-underline-position: under;
  color: var(--vscode-textLink-foreground);
  color: var(--vscode-textLink-activeForeground);
  border-bottom: 1px solid #0000;
  text-decoration: underline;
}

.monaco-editor .marker-widget .descriptioncontainer .filename {
  cursor: pointer;
}

.monaco-editor .zone-widget .codicon.codicon-error, .markers-panel .marker-icon.error, .markers-panel .marker-icon .codicon.codicon-error, .text-search-provider-messages .providerMessage .codicon.codicon-error, .extensions-viewlet > .extensions .codicon.codicon-error, .extension-editor .codicon.codicon-error, .preferences-editor .codicon.codicon-error {
  color: var(--vscode-problemsErrorIcon-foreground);
}

.monaco-editor .zone-widget .codicon.codicon-warning, .markers-panel .marker-icon.warning, .markers-panel .marker-icon .codicon.codicon-warning, .text-search-provider-messages .providerMessage .codicon.codicon-warning, .extensions-viewlet > .extensions .codicon.codicon-warning, .extension-editor .codicon.codicon-warning, .preferences-editor .codicon.codicon-warning {
  color: var(--vscode-problemsWarningIcon-foreground);
}

.monaco-editor .zone-widget .codicon.codicon-info, .markers-panel .marker-icon.info, .markers-panel .marker-icon .codicon.codicon-info, .text-search-provider-messages .providerMessage .codicon.codicon-info, .extensions-viewlet > .extensions .codicon.codicon-info, .extension-editor .codicon.codicon-info, .preferences-editor .codicon.codicon-info {
  color: var(--vscode-problemsInfoIcon-foreground);
}

.monaco-editor .hoverHighlight {
  background-color: var(--vscode-editor-hoverHighlightBackground);
}

.monaco-editor .monaco-hover {
  color: var(--vscode-editorHoverWidget-foreground);
  background-color: var(--vscode-editorHoverWidget-background);
  border: 1px solid var(--vscode-editorHoverWidget-border);
}

.monaco-editor .monaco-hover a {
  color: var(--vscode-textLink-foreground);
}

.monaco-editor .monaco-hover a:hover {
  color: var(--vscode-textLink-activeForeground);
}

.monaco-editor .monaco-hover .hover-row .actions {
  background-color: var(--vscode-editorHoverWidget-statusBarBackground);
}

.monaco-editor .monaco-hover code {
  background-color: var(--vscode-textCodeBlock-background);
}

.monaco-editor .inlineSuggestionsHints.withBorder {
  z-index: 39;
  color: var(--vscode-editorHoverWidget-foreground);
  background-color: var(--vscode-editorHoverWidget-background);
  border: 1px solid var(--vscode-editorHoverWidget-border);
}

.monaco-editor .inlineSuggestionsHints a, .monaco-editor .inlineSuggestionsHints a:hover {
  color: var(--vscode-foreground);
}

.monaco-editor .inlineSuggestionsHints .keybinding {
  opacity: .6;
  margin-left: 4px;
  display: flex;
}

.monaco-editor .inlineSuggestionsHints .keybinding .monaco-keybinding-key {
  padding: 2px 3px;
  font-size: 8px;
}

.monaco-editor .inlineSuggestionsHints .custom-actions .action-item:nth-child(2) a {
  min-width: 19px;
  justify-content: center;
  display: flex;
}

.monaco-editor .inlineSuggestionStatusBarItemLabel {
  margin-right: 2px;
}

.monaco-toolbar {
  height: 100%;
}

.monaco-toolbar .toolbar-toggle-more {
  padding: 0;
  display: inline-block;
}

.monaco-editor.vs .dnd-target, .monaco-editor.hc-light .dnd-target {
  color: #fff;
  border-right: 2px dotted #000;
}

.monaco-editor.vs-dark .dnd-target {
  color: #51504f;
  border-right: 2px dotted #aeafad;
}

.monaco-editor.hc-black .dnd-target {
  color: #000;
  border-right: 2px dotted #fff;
}

.monaco-editor.mouse-default .view-lines, .monaco-editor.vs-dark.mac.mouse-default .view-lines, .monaco-editor.hc-black.mac.mouse-default .view-lines, .monaco-editor.hc-light.mac.mouse-default .view-lines {
  cursor: default;
}

.monaco-editor.mouse-copy .view-lines, .monaco-editor.vs-dark.mac.mouse-copy .view-lines, .monaco-editor.hc-black.mac.mouse-copy .view-lines, .monaco-editor.hc-light.mac.mouse-copy .view-lines {
  cursor: copy;
}

.monaco-editor .findOptionsWidget {
  background-color: var(--vscode-editorWidget-background);
  color: var(--vscode-editorWidget-foreground);
  box-shadow: 0 0 8px 2px var(--vscode-widget-shadow);
  border: 2px solid var(--vscode-contrastBorder);
}

.monaco-editor .find-widget {
  z-index: 35;
  height: 33px;
  box-sizing: border-box;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 4px;
  line-height: 19px;
  transition: transform .2s linear;
  position: absolute;
  overflow: hidden;
  transform: translateY(calc(-100% - 10px));
}

.monaco-workbench.reduce-motion .monaco-editor .find-widget {
  transition: transform linear;
}

.monaco-editor .find-widget textarea {
  margin: 0;
}

.monaco-editor .find-widget.hiddenEditor {
  display: none;
}

.monaco-editor .find-widget.replaceToggled > .replace-part {
  display: flex;
}

.monaco-editor .find-widget.visible {
  transform: translateY(0);
}

.monaco-editor .find-widget .monaco-inputbox.synthetic-focus {
  outline: 1px solid -webkit-focus-ring-color;
  outline-offset: -1px;
}

.monaco-editor .find-widget .monaco-inputbox .input {
  min-height: 0;
  background-color: #0000;
}

.monaco-editor .find-widget .monaco-findInput .input {
  font-size: 13px;
}

.monaco-editor .find-widget > .find-part, .monaco-editor .find-widget > .replace-part {
  margin: 3px 0 0 17px;
  font-size: 12px;
  display: flex;
}

.monaco-editor .find-widget > .find-part .monaco-inputbox, .monaco-editor .find-widget > .replace-part .monaco-inputbox {
  min-height: 25px;
}

.monaco-editor .find-widget > .replace-part .monaco-inputbox > .ibwrapper > .mirror {
  padding-right: 22px;
}

.monaco-editor .find-widget > .find-part .monaco-inputbox > .ibwrapper > .input, .monaco-editor .find-widget > .find-part .monaco-inputbox > .ibwrapper > .mirror, .monaco-editor .find-widget > .replace-part .monaco-inputbox > .ibwrapper > .input, .monaco-editor .find-widget > .replace-part .monaco-inputbox > .ibwrapper > .mirror {
  padding-top: 2px;
  padding-bottom: 2px;
}

.monaco-editor .find-widget > .find-part .find-actions, .monaco-editor .find-widget > .replace-part .replace-actions {
  height: 25px;
  align-items: center;
  display: flex;
}

.monaco-editor .find-widget .monaco-findInput {
  vertical-align: middle;
  flex: 1;
  display: flex;
}

.monaco-editor .find-widget .monaco-findInput .monaco-scrollable-element {
  width: 100%;
}

.monaco-editor .find-widget .monaco-findInput .monaco-scrollable-element .scrollbar.vertical {
  opacity: 0;
}

.monaco-editor .find-widget .matchesCount {
  flex: initial;
  height: 25px;
  vertical-align: middle;
  box-sizing: border-box;
  text-align: center;
  margin: 0 0 0 3px;
  padding: 2px 0 0 2px;
  line-height: 23px;
  display: flex;
}

.monaco-editor .find-widget .button {
  width: 16px;
  height: 16px;
  flex: initial;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
  padding: 3px;
  display: flex;
}

.monaco-editor .find-widget .codicon-find-selection {
  width: 22px;
  height: 22px;
  border-radius: 5px;
  padding: 3px;
}

.monaco-editor .find-widget .button.left {
  margin-left: 0;
  margin-right: 3px;
}

.monaco-editor .find-widget .button.wide {
  width: auto;
  padding: 1px 6px;
  top: -1px;
}

.monaco-editor .find-widget .button.toggle {
  width: 18px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0;
  position: absolute;
  top: 0;
  left: 3px;
}

.monaco-editor .find-widget .button.toggle.disabled {
  display: none;
}

.monaco-editor .find-widget .disabled {
  color: var(--vscode-disabledForeground);
  cursor: default;
}

.monaco-editor .find-widget > .replace-part {
  display: none;
}

.monaco-editor .find-widget > .replace-part > .monaco-findInput {
  vertical-align: middle;
  flex: none;
  display: flex;
  position: relative;
}

.monaco-editor .find-widget > .replace-part > .monaco-findInput > .controls {
  position: absolute;
  top: 3px;
  right: 2px;
}

.monaco-editor .find-widget.reduced-find-widget .matchesCount {
  display: none;
}

.monaco-editor .find-widget.narrow-find-widget {
  max-width: 257px !important;
}

.monaco-editor .find-widget.collapsed-find-widget {
  max-width: 170px !important;
}

.monaco-editor .find-widget.collapsed-find-widget .button.previous, .monaco-editor .find-widget.collapsed-find-widget .button.next, .monaco-editor .find-widget.collapsed-find-widget .button.replace, .monaco-editor .find-widget.collapsed-find-widget .button.replace-all, .monaco-editor .find-widget.collapsed-find-widget > .find-part .monaco-findInput .controls {
  display: none;
}

.monaco-editor .findMatch {
  animation-duration: 0;
  animation-name: inherit !important;
}

.monaco-editor .find-widget .monaco-sash {
  left: 0 !important;
}

.monaco-editor.hc-black .find-widget .button:before {
  position: relative;
  top: 1px;
  left: 2px;
}

.monaco-editor .margin-view-overlays .codicon-folding-manual-collapsed, .monaco-editor .margin-view-overlays .codicon-folding-manual-expanded, .monaco-editor .margin-view-overlays .codicon-folding-expanded, .monaco-editor .margin-view-overlays .codicon-folding-collapsed {
  cursor: pointer;
  opacity: 0;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  font-size: 140%;
  transition: opacity .5s;
  display: flex;
}

.monaco-workbench.reduce-motion .monaco-editor .margin-view-overlays .codicon-folding-manual-collapsed, .monaco-workbench.reduce-motion .monaco-editor .margin-view-overlays .codicon-folding-manual-expanded, .monaco-workbench.reduce-motion .monaco-editor .margin-view-overlays .codicon-folding-expanded, .monaco-workbench.reduce-motion .monaco-editor .margin-view-overlays .codicon-folding-collapsed {
  transition: initial;
}

.monaco-editor .margin-view-overlays:hover .codicon, .monaco-editor .margin-view-overlays .codicon.codicon-folding-collapsed, .monaco-editor .margin-view-overlays .codicon.codicon-folding-manual-collapsed, .monaco-editor .margin-view-overlays .codicon.alwaysShowFoldIcons {
  opacity: 1;
}

.monaco-editor .inline-folded:after {
  color: gray;
  content: "⋯";
  cursor: pointer;
  margin: .1em .2em 0;
  line-height: 1em;
  display: inline;
}

.monaco-editor .folded-background {
  background-color: var(--vscode-editor-foldBackground);
}

.monaco-editor .cldr.codicon.codicon-folding-expanded, .monaco-editor .cldr.codicon.codicon-folding-collapsed, .monaco-editor .cldr.codicon.codicon-folding-manual-expanded, .monaco-editor .cldr.codicon.codicon-folding-manual-collapsed {
  color: var(--vscode-editorGutter-foldingControlForeground) !important;
}

.monaco-editor .snippet-placeholder {
  min-width: 2px;
  background-color: var(--vscode-editor-snippetTabstopHighlightBackground, transparent);
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--vscode-editor-snippetTabstopHighlightBorder, transparent);
}

.monaco-editor .finish-snippet-placeholder {
  background-color: var(--vscode-editor-snippetFinalTabstopHighlightBackground, transparent);
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--vscode-editor-snippetFinalTabstopHighlightBorder, transparent);
}

.monaco-editor .suggest-widget {
  width: 430px;
  z-index: 40;
  flex-direction: column;
  display: flex;
}

.monaco-editor .suggest-widget.message {
  flex-direction: row;
  align-items: center;
}

.monaco-editor .suggest-widget, .monaco-editor .suggest-details {
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: var(--vscode-editorSuggestWidget-border);
  background-color: var(--vscode-editorSuggestWidget-background);
  flex: 0 auto;
}

.monaco-editor.hc-black .suggest-widget, .monaco-editor.hc-black .suggest-details, .monaco-editor.hc-light .suggest-widget, .monaco-editor.hc-light .suggest-details {
  border-width: 2px;
}

.monaco-editor .suggest-widget .suggest-status-bar {
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid var(--vscode-editorSuggestWidget-border);
  flex-flow: row;
  justify-content: space-between;
  padding: 0 4px;
  font-size: 80%;
  display: none;
  overflow: hidden;
}

.monaco-editor .suggest-widget.with-status-bar .suggest-status-bar {
  display: flex;
}

.monaco-editor .suggest-widget .suggest-status-bar .left {
  padding-right: 8px;
}

.monaco-editor .suggest-widget.with-status-bar .suggest-status-bar .action-label {
  color: var(--vscode-editorSuggestWidgetStatus-foreground);
}

.monaco-editor .suggest-widget.with-status-bar .suggest-status-bar .action-item:not(:last-of-type) .action-label {
  margin-right: 0;
}

.monaco-editor .suggest-widget.with-status-bar .suggest-status-bar .action-item:not(:last-of-type) .action-label:after {
  content: ", ";
  margin-right: .3em;
}

.monaco-editor .suggest-widget.with-status-bar .monaco-list .monaco-list-row > .contents > .main > .right > .readMore, .monaco-editor .suggest-widget.with-status-bar .monaco-list .monaco-list-row.focused.string-label > .contents > .main > .right > .readMore {
  display: none;
}

.monaco-editor .suggest-widget.with-status-bar:not(.docs-side) .monaco-list .monaco-list-row:hover > .contents > .main > .right.can-expand-details > .details-label {
  width: 100%;
}

.monaco-editor .suggest-widget > .message {
  padding-left: 22px;
}

.monaco-editor .suggest-widget > .tree {
  height: 100%;
  width: 100%;
}

.monaco-editor .suggest-widget .monaco-list {
  -webkit-user-select: none;
  user-select: none;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row {
  -mox-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
  cursor: pointer;
  touch-action: none;
  background-position: 2px 2px;
  background-repeat: no-repeat;
  padding-right: 10px;
  display: flex;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.focused {
  color: var(--vscode-editorSuggestWidget-selectedForeground);
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.focused .codicon {
  color: var(--vscode-editorSuggestWidget-selectedIconForeground);
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents {
  height: 100%;
  flex: 1;
  padding-left: 2px;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main {
  text-overflow: ellipsis;
  white-space: pre;
  justify-content: space-between;
  display: flex;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .left, .monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right {
  display: flex;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row:not(.focused) > .contents > .main .monaco-icon-label {
  color: var(--vscode-editorSuggestWidget-foreground);
}

.monaco-editor .suggest-widget:not(.frozen) .monaco-highlighted-label .highlight {
  font-weight: bold;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main .monaco-highlighted-label .highlight {
  color: var(--vscode-editorSuggestWidget-highlightForeground);
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.focused > .contents > .main .monaco-highlighted-label .highlight {
  color: var(--vscode-editorSuggestWidget-focusHighlightForeground);
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .header > .codicon-close, .monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .readMore:before {
  color: inherit;
  opacity: 1;
  cursor: pointer;
  font-size: 14px;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .header > .codicon-close {
  position: absolute;
  top: 6px;
  right: 2px;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .header > .codicon-close:hover, .monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .readMore:hover {
  opacity: 1;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .details-label {
  opacity: .7;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .left > .signature-label {
  text-overflow: ellipsis;
  opacity: .6;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .left > .qualifier-label {
  opacity: .4;
  font-size: 85%;
  line-height: initial;
  text-overflow: ellipsis;
  align-self: center;
  margin-left: 12px;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .details-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 1.1em;
  font-size: 85%;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .details-label > .monaco-tokenized-source {
  display: inline;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .details-label {
  display: none;
}

.monaco-editor .suggest-widget:not(.shows-details) .monaco-list .monaco-list-row.focused > .contents > .main > .right > .details-label, .monaco-editor .suggest-widget .monaco-list .monaco-list-row:not(.string-label) > .contents > .main > .right > .details-label, .monaco-editor .suggest-widget.docs-side .monaco-list .monaco-list-row.focused:not(.string-label) > .contents > .main > .right > .details-label {
  display: inline;
}

.monaco-editor .suggest-widget:not(.docs-side) .monaco-list .monaco-list-row.focused:hover > .contents > .main > .right.can-expand-details > .details-label {
  width: calc(100% - 26px);
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .left {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .left > .monaco-icon-label {
  flex-shrink: 0;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row:not(.string-label) > .contents > .main > .left > .monaco-icon-label {
  max-width: 100%;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.string-label > .contents > .main > .left > .monaco-icon-label {
  flex-shrink: 1;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right {
  max-width: 70%;
  flex-shrink: 4;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .readMore {
  width: 18px;
  height: 18px;
  visibility: hidden;
  display: inline-block;
  position: absolute;
  right: 10px;
}

.monaco-editor .suggest-widget.docs-side .monaco-list .monaco-list-row > .contents > .main > .right > .readMore {
  display: none !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.string-label > .contents > .main > .right > .readMore {
  display: none;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.focused.string-label > .contents > .main > .right > .readMore {
  display: inline-block;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.focused:hover > .contents > .main > .right > .readMore {
  visibility: visible;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .monaco-icon-label.deprecated {
  opacity: .66;
  -webkit-text-decoration: unset;
  text-decoration: unset;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .monaco-icon-label.deprecated > .monaco-icon-label-container > .monaco-icon-name-container {
  text-decoration: line-through;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .monaco-icon-label:before {
  height: 100%;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .icon {
  height: 16px;
  width: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  margin-left: 2px;
  display: block;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .icon.hide {
  display: none;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon {
  align-items: center;
  margin-right: 4px;
  display: flex;
}

.monaco-editor .suggest-widget.no-icons .monaco-list .monaco-list-row .icon, .monaco-editor .suggest-widget.no-icons .monaco-list .monaco-list-row .suggest-icon:before {
  display: none;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .icon.customcolor .colorspan {
  width: .7em;
  height: .7em;
  border: .1em solid #000;
  margin: 0 0 0 .3em;
  display: inline-block;
}

.monaco-editor .suggest-details-container {
  z-index: 41;
}

.monaco-editor .suggest-details {
  cursor: default;
  color: var(--vscode-editorSuggestWidget-foreground);
  flex-direction: column;
  display: flex;
}

.monaco-editor .suggest-details.focused {
  border-color: var(--vscode-focusBorder);
}

.monaco-editor .suggest-details a {
  color: var(--vscode-textLink-foreground);
}

.monaco-editor .suggest-details a:hover {
  color: var(--vscode-textLink-activeForeground);
}

.monaco-editor .suggest-details code {
  background-color: var(--vscode-textCodeBlock-background);
}

.monaco-editor .suggest-details.no-docs {
  display: none;
}

.monaco-editor .suggest-details > .monaco-scrollable-element {
  flex: 1;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .header > .type {
  text-overflow: ellipsis;
  opacity: .7;
  white-space: pre;
  flex: 2;
  margin: 0 24px 0 0;
  padding: 4px 0 12px 5px;
  overflow: hidden;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .header > .type.auto-wrap {
  white-space: normal;
  word-break: break-all;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs {
  white-space: pre-wrap;
  margin: 0;
  padding: 4px 5px;
}

.monaco-editor .suggest-details.no-type > .monaco-scrollable-element > .body > .docs {
  margin-right: 24px;
  overflow: hidden;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs {
  white-space: initial;
  min-height: calc(1rem + 8px);
  padding: 0;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs > div, .monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs > span:not(:empty) {
  padding: 4px 5px;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs > div > p:first-child {
  margin-top: 0;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs > div > p:last-child {
  margin-bottom: 0;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs .monaco-tokenized-source {
  white-space: pre;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs .code {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs .codicon {
  vertical-align: sub;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > p:empty {
  display: none;
}

.monaco-editor .suggest-details code {
  border-radius: 3px;
  padding: 0 .4em;
}

.monaco-editor .suggest-details ul, .monaco-editor .suggest-details ol {
  padding-left: 20px;
}

.monaco-editor .suggest-details p code {
  font-family: var(--monaco-monospace-font);
}

.monaco-editor .suggest-preview-additional-widget {
  white-space: nowrap;
}

.monaco-editor .suggest-preview-additional-widget .content-spacer {
  color: #0000;
  white-space: pre;
}

.monaco-editor .suggest-preview-additional-widget .button {
  cursor: pointer;
  text-underline-position: under;
  text-decoration: underline;
  display: inline-block;
}

.monaco-editor .ghost-text-hidden {
  opacity: 0;
  font-size: 0;
}

.monaco-editor .ghost-text-decoration, .monaco-editor .suggest-preview-text {
  font-style: italic;
}

.monaco-editor .inline-completion-text-to-replace {
  text-underline-position: under;
  text-decoration: underline;
}

.monaco-editor .ghost-text-decoration, .monaco-editor .ghost-text-decoration-preview, .monaco-editor .suggest-preview-text .ghost-text {
  background-color: var(--vscode-editorGhostText-background);
  border: 1px solid var(--vscode-editorGhostText-border);
  color: var(--vscode-editorGhostText-foreground) !important;
}

.monaco-editor.vs .valueSetReplacement {
  outline: solid 2px var(--vscode-editorBracketMatch-border);
}

.monaco-editor .linked-editing-decoration {
  background-color: var(--vscode-editor-linkedEditingBackground);
  border-left-color: var(--vscode-editor-linkedEditingBackground);
}

.monaco-editor .detected-link, .monaco-editor .detected-link-active {
  text-underline-position: under;
  text-decoration: underline;
}

.monaco-editor .detected-link-active {
  cursor: pointer;
  color: var(--vscode-editorLink-activeForeground) !important;
}

.monaco-editor .focused .selectionHighlight {
  background-color: var(--vscode-editor-selectionHighlightBackground);
  box-sizing: border-box;
  border: 1px solid var(--vscode-editor-selectionHighlightBorder);
}

.monaco-editor.hc-black .focused .selectionHighlight, .monaco-editor.hc-light .focused .selectionHighlight {
  border-style: dotted;
}

.monaco-editor .wordHighlight {
  background-color: var(--vscode-editor-wordHighlightBackground);
  box-sizing: border-box;
  border: 1px solid var(--vscode-editor-wordHighlightBorder);
}

.monaco-editor.hc-black .wordHighlight, .monaco-editor.hc-light .wordHighlight {
  border-style: dotted;
}

.monaco-editor .wordHighlightStrong {
  background-color: var(--vscode-editor-wordHighlightStrongBackground);
  box-sizing: border-box;
  border: 1px solid var(--vscode-editor-wordHighlightStrongBorder);
}

.monaco-editor.hc-black .wordHighlightStrong, .monaco-editor.hc-light .wordHighlightStrong {
  border-style: dotted;
}

.monaco-editor .wordHighlightText {
  background-color: var(--vscode-editor-wordHighlightTextBackground);
  box-sizing: border-box;
  border: 1px solid var(--vscode-editor-wordHighlightTextBorder);
}

.monaco-editor.hc-black .wordHighlightText, .monaco-editor.hc-light .wordHighlightText {
  border-style: dotted;
}

.monaco-editor .parameter-hints-widget {
  z-index: 39;
  cursor: default;
  color: var(--vscode-editorHoverWidget-foreground);
  background-color: var(--vscode-editorHoverWidget-background);
  border: 1px solid var(--vscode-editorHoverWidget-border);
  flex-direction: column;
  line-height: 1.5em;
  display: flex;
}

.hc-black .monaco-editor .parameter-hints-widget, .hc-light .monaco-editor .parameter-hints-widget {
  border-width: 2px;
}

.monaco-editor .parameter-hints-widget > .phwrapper {
  max-width: 440px;
  flex-direction: row;
  display: flex;
}

.monaco-editor .parameter-hints-widget.multiple {
  min-height: 3.3em;
  padding: 0;
}

.monaco-editor .parameter-hints-widget.multiple .body:before {
  content: "";
  height: 100%;
  opacity: .5;
  border-left: 1px solid var(--vscode-editorHoverWidget-border);
  display: block;
  position: absolute;
}

.monaco-editor .parameter-hints-widget p, .monaco-editor .parameter-hints-widget ul {
  margin: 8px 0;
}

.monaco-editor .parameter-hints-widget .monaco-scrollable-element, .monaco-editor .parameter-hints-widget .body {
  min-height: 100%;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.monaco-editor .parameter-hints-widget .signature {
  padding: 4px 5px;
  position: relative;
}

.monaco-editor .parameter-hints-widget .signature.has-docs:after {
  content: "";
  width: 100%;
  opacity: .5;
  border-bottom: 1px solid var(--vscode-editorHoverWidget-border);
  padding-top: 4px;
  display: block;
  position: absolute;
  left: 0;
}

.monaco-editor .parameter-hints-widget .docs {
  white-space: pre-wrap;
  padding: 0 10px 0 5px;
}

.monaco-editor .parameter-hints-widget .docs.empty {
  display: none;
}

.monaco-editor .parameter-hints-widget .docs a {
  color: var(--vscode-textLink-foreground);
}

.monaco-editor .parameter-hints-widget .docs a:hover {
  color: var(--vscode-textLink-activeForeground);
  cursor: pointer;
}

.monaco-editor .parameter-hints-widget .docs .markdown-docs {
  white-space: initial;
}

.monaco-editor .parameter-hints-widget .docs code {
  font-family: var(--monaco-monospace-font);
  background-color: var(--vscode-textCodeBlock-background);
  border-radius: 3px;
  padding: 0 .4em;
}

.monaco-editor .parameter-hints-widget .docs .monaco-tokenized-source, .monaco-editor .parameter-hints-widget .docs .code {
  white-space: pre-wrap;
}

.monaco-editor .parameter-hints-widget .controls {
  min-width: 22px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: none;
}

.monaco-editor .parameter-hints-widget.multiple .controls {
  padding: 0 2px;
  display: flex;
}

.monaco-editor .parameter-hints-widget.multiple .button {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-repeat: no-repeat;
}

.monaco-editor .parameter-hints-widget .button.previous {
  bottom: 24px;
}

.monaco-editor .parameter-hints-widget .overloads {
  text-align: center;
  height: 12px;
  line-height: 12px;
  font-family: var(--monaco-monospace-font);
}

.monaco-editor .parameter-hints-widget .signature .parameter.active {
  color: var(--vscode-editorHoverWidget-highlightForeground);
  font-weight: bold;
}

.monaco-editor .parameter-hints-widget .documentation-parameter > .parameter {
  margin-right: .5em;
  font-weight: bold;
}

.monaco-editor .rename-box {
  z-index: 100;
  color: inherit;
  border-radius: 4px;
}

.monaco-editor .rename-box.preview {
  padding: 4px 4px 0;
}

.monaco-editor .rename-box .rename-input {
  border-radius: 2px;
  padding: 3px;
}

.monaco-editor .rename-box .rename-label {
  opacity: .8;
  display: none;
}

.monaco-editor .rename-box.preview .rename-label {
  display: inherit;
}

.monaco-editor .sticky-line {
  color: var(--vscode-editorLineNumber-foreground);
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
}

.monaco-editor .sticky-line-number {
  text-align: right;
  float: left;
}

.monaco-editor .sticky-line-root {
  background-color: inherit;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.monaco-editor.hc-black .sticky-widget, .monaco-editor.hc-light .sticky-widget {
  border-bottom: 1px solid var(--vscode-contrastBorder);
}

.monaco-editor .sticky-line-root:hover {
  background-color: var(--vscode-editorStickyScrollHover-background);
  cursor: pointer;
}

.monaco-editor .sticky-widget {
  width: 100%;
  box-shadow: var(--vscode-scrollbar-shadow) 0 3px 2px -2px;
  z-index: 4;
  background-color: var(--vscode-editorStickyScroll-background);
}

.monaco-editor .sticky-widget.peek {
  background-color: var(--vscode-peekViewEditorStickyScroll-background);
}

.monaco-editor .unicode-highlight {
  border: 1px solid var(--vscode-editorUnicodeHighlight-border);
  background-color: var(--vscode-editorUnicodeHighlight-background);
  box-sizing: border-box;
}

.editor-banner {
  box-sizing: border-box;
  cursor: default;
  width: 100%;
  height: 26px;
  background: var(--vscode-banner-background);
  font-size: 12px;
  display: flex;
  overflow: visible;
}

.editor-banner .icon-container {
  flex-shrink: 0;
  align-items: center;
  padding: 0 6px 0 10px;
  display: flex;
}

.editor-banner .icon-container.custom-icon {
  width: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  margin: 0 6px 0 10px;
  padding: 0;
}

.editor-banner .message-container {
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  line-height: 26px;
  display: flex;
  overflow: hidden;
}

.editor-banner .message-container p {
  margin-block: 0;
}

.editor-banner .message-actions-container {
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0 4px;
  line-height: 26px;
}

.editor-banner .message-actions-container a.monaco-button {
  width: inherit;
  margin: 2px 8px;
  padding: 0 12px;
}

.editor-banner .message-actions-container a {
  margin-left: 12px;
  padding: 3px;
  text-decoration: underline;
}

.editor-banner .action-container {
  padding: 0 10px 0 6px;
}

.editor-banner {
  background-color: var(--vscode-banner-background);
}

.editor-banner, .editor-banner .action-container .codicon, .editor-banner .message-actions-container .monaco-link {
  color: var(--vscode-banner-foreground);
}

.editor-banner .icon-container .codicon {
  color: var(--vscode-banner-iconForeground);
}

.monaco-link {
  color: var(--vscode-textLink-foreground);
}

.monaco-link:hover {
  color: var(--vscode-textLink-activeForeground);
}

.monaco-editor .accessibilityHelpWidget {
  vertical-align: middle;
  color: var(--vscode-editorWidget-foreground);
  background-color: var(--vscode-editorWidget-background);
  box-shadow: 0 2px 8px var(--vscode-widget-shadow);
  border: 2px solid var(--vscode-contrastBorder);
  padding: 10px;
  overflow: scroll;
}

.monaco-editor .iPadShowKeyboard {
  width: 58px;
  min-width: 0;
  height: 36px;
  min-height: 0;
  resize: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTMiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCA1MyAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDguMDM2NCA0LjAxMDQySDQuMDA3NzlMNC4wMDc3OSAzMi4wMjg2SDQ4LjAzNjRWNC4wMTA0MlpNNC4wMDc3OSAwLjAwNzgxMjVDMS43OTcyMSAwLjAwNzgxMjUgMC4wMDUxODc5OSAxLjc5OTg0IDAuMDA1MTg3OTkgNC4wMTA0MlYzMi4wMjg2QzAuMDA1MTg3OTkgMzQuMjM5MiAxLjc5NzIxIDM2LjAzMTIgNC4wMDc3OSAzNi4wMzEySDQ4LjAzNjRDNTAuMjQ3IDM2LjAzMTIgNTIuMDM5IDM0LjIzOTIgNTIuMDM5IDMyLjAyODZWNC4wMTA0MkM1Mi4wMzkgMS43OTk4NCA1MC4yNDcgMC4wMDc4MTI1IDQ4LjAzNjQgMC4wMDc4MTI1SDQuMDA3NzlaTTguMDEwNDIgOC4wMTMwMkgxMi4wMTNWMTIuMDE1Nkg4LjAxMDQyVjguMDEzMDJaTTIwLjAxODIgOC4wMTMwMkgxNi4wMTU2VjEyLjAxNTZIMjAuMDE4MlY4LjAxMzAyWk0yNC4wMjA4IDguMDEzMDJIMjguMDIzNFYxMi4wMTU2SDI0LjAyMDhWOC4wMTMwMlpNMzYuMDI4NiA4LjAxMzAySDMyLjAyNlYxMi4wMTU2SDM2LjAyODZWOC4wMTMwMlpNNDAuMDMxMiA4LjAxMzAySDQ0LjAzMzlWMTIuMDE1Nkg0MC4wMzEyVjguMDEzMDJaTTE2LjAxNTYgMTYuMDE4Mkg4LjAxMDQyVjIwLjAyMDhIMTYuMDE1NlYxNi4wMTgyWk0yMC4wMTgyIDE2LjAxODJIMjQuMDIwOFYyMC4wMjA4SDIwLjAxODJWMTYuMDE4MlpNMzIuMDI2IDE2LjAxODJIMjguMDIzNFYyMC4wMjA4SDMyLjAyNlYxNi4wMTgyWk00NC4wMzM5IDE2LjAxODJWMjAuMDIwOEgzNi4wMjg2VjE2LjAxODJINDQuMDMzOVpNMTIuMDEzIDI0LjAyMzRIOC4wMTA0MlYyOC4wMjZIMTIuMDEzVjI0LjAyMzRaTTE2LjAxNTYgMjQuMDIzNEgzNi4wMjg2VjI4LjAyNkgxNi4wMTU2VjI0LjAyMzRaTTQ0LjAzMzkgMjQuMDIzNEg0MC4wMzEyVjI4LjAyNkg0NC4wMzM5VjI0LjAyMzRaIiBmaWxsPSIjNDI0MjQyIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iNTMiIGhlaWdodD0iMzYiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==") center no-repeat;
  border: 4px solid #f6f6f6;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.monaco-editor.vs-dark .iPadShowKeyboard {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTMiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCA1MyAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDguMDM2NCA0LjAxMDQySDQuMDA3NzlMNC4wMDc3OSAzMi4wMjg2SDQ4LjAzNjRWNC4wMTA0MlpNNC4wMDc3OSAwLjAwNzgxMjVDMS43OTcyMSAwLjAwNzgxMjUgMC4wMDUxODc5OSAxLjc5OTg0IDAuMDA1MTg3OTkgNC4wMTA0MlYzMi4wMjg2QzAuMDA1MTg3OTkgMzQuMjM5MiAxLjc5NzIxIDM2LjAzMTIgNC4wMDc3OSAzNi4wMzEySDQ4LjAzNjRDNTAuMjQ3IDM2LjAzMTIgNTIuMDM5IDM0LjIzOTIgNTIuMDM5IDMyLjAyODZWNC4wMTA0MkM1Mi4wMzkgMS43OTk4NCA1MC4yNDcgMC4wMDc4MTI1IDQ4LjAzNjQgMC4wMDc4MTI1SDQuMDA3NzlaTTguMDEwNDIgOC4wMTMwMkgxMi4wMTNWMTIuMDE1Nkg4LjAxMDQyVjguMDEzMDJaTTIwLjAxODIgOC4wMTMwMkgxNi4wMTU2VjEyLjAxNTZIMjAuMDE4MlY4LjAxMzAyWk0yNC4wMjA4IDguMDEzMDJIMjguMDIzNFYxMi4wMTU2SDI0LjAyMDhWOC4wMTMwMlpNMzYuMDI4NiA4LjAxMzAySDMyLjAyNlYxMi4wMTU2SDM2LjAyODZWOC4wMTMwMlpNNDAuMDMxMiA4LjAxMzAySDQ0LjAzMzlWMTIuMDE1Nkg0MC4wMzEyVjguMDEzMDJaTTE2LjAxNTYgMTYuMDE4Mkg4LjAxMDQyVjIwLjAyMDhIMTYuMDE1NlYxNi4wMTgyWk0yMC4wMTgyIDE2LjAxODJIMjQuMDIwOFYyMC4wMjA4SDIwLjAxODJWMTYuMDE4MlpNMzIuMDI2IDE2LjAxODJIMjguMDIzNFYyMC4wMjA4SDMyLjAyNlYxNi4wMTgyWk00NC4wMzM5IDE2LjAxODJWMjAuMDIwOEgzNi4wMjg2VjE2LjAxODJINDQuMDMzOVpNMTIuMDEzIDI0LjAyMzRIOC4wMTA0MlYyOC4wMjZIMTIuMDEzVjI0LjAyMzRaTTE2LjAxNTYgMjQuMDIzNEgzNi4wMjg2VjI4LjAyNkgxNi4wMTU2VjI0LjAyMzRaTTQ0LjAzMzkgMjQuMDIzNEg0MC4wMzEyVjI4LjAyNkg0NC4wMzM5VjI0LjAyMzRaIiBmaWxsPSIjQzVDNUM1Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iNTMiIGhlaWdodD0iMzYiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==") center no-repeat;
  border: 4px solid #252526;
}

.monaco-editor .tokens-inspect-widget {
  z-index: 50;
  -webkit-user-select: text;
  user-select: text;
  color: var(--vscode-editorHoverWidget-foreground);
  background-color: var(--vscode-editorHoverWidget-background);
  border: 1px solid var(--vscode-editorHoverWidget-border);
  padding: 10px;
}

.monaco-editor.hc-black .tokens-inspect-widget, .monaco-editor.hc-light .tokens-inspect-widget {
  border-width: 2px;
}

.monaco-editor .tokens-inspect-widget .tokens-inspect-separator {
  height: 1px;
  background-color: var(--vscode-editorHoverWidget-border);
  border: 0;
}

.monaco-editor .tokens-inspect-widget .tm-token {
  font-family: var(--monaco-monospace-font);
}

.monaco-editor .tokens-inspect-widget .tm-token-length {
  float: right;
  font-size: 60%;
  font-weight: normal;
}

.monaco-editor .tokens-inspect-widget .tm-metadata-table {
  width: 100%;
}

.monaco-editor .tokens-inspect-widget .tm-metadata-value {
  font-family: var(--monaco-monospace-font);
  text-align: right;
}

.monaco-editor .tokens-inspect-widget .tm-token-type {
  font-family: var(--monaco-monospace-font);
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=index.08aab54f.css.map */
